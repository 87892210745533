@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

// repeat the class name here so we have priority over the MUI style
.grid-container.grid-container {
  display: block;
  columns: 1;
  @include breakpoint(sm) {
    columns: 2;
  }
  @include breakpoint(md) {
    columns: 3;
  }
  @include breakpoint(xl) {
    columns: 4;
  }
}

.checkbox-container {
  display: flex;
}
