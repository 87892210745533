@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.section {
  table {
    td {
      border: 0 solid transparent;
    }

    tr > td + td {
      border-left-width: 1rem;
    }
  }

  .tag {
    @include font-weight(semibold);
    background-color: $darkest-gray;
    border-radius: 0.6rem;
    color: $white;
    display: inline-block;
    font-size: 0.8rem;
    padding: 0.25rem 0.75rem;
    text-decoration: none;

    &.red {
      background-color: $accme-red;
    }

    &.turquoise {
      background-color: $accme-turquoise;
    }

    &.purple {
      background-color: $accme-purple;
    }

    &.gray {
      background-color: $medium-dark-gray;
    }

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}
