@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

.button-row {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: spacing(lg);
  margin-bottom: spacing(md);
  width: 100%;

  > button {
    margin-left: spacing(sm);
  }
}

.summary-input {
  margin-bottom: spacing(md);
  margin-top: spacing(sm);
  width: 50%;
  @include breakpoint-max(sm) {
    width: 100%;
  }
}

.field-separator {
  margin-bottom: spacing(md);
}

.disabled-input {
  cursor: not-allowed;
}

.top-border {
  @include thin-border-top;
  padding-top: 1.5rem;
}

.re-open-button {
  @include body-m;
  color: $darkest-gray;
  margin-bottom: spacing(md);
  padding: 0;

  .re-open-svg {
    margin-right: spacing(xs);
    padding: spacing(xxs);
  }
}


