@import 'functions';
@import 'mixins';
@import 'variables';

a {
  @include body-m;
  color: $accme-blue;
  outline: none;
  text-align: left;
  text-decoration: none;

  &:visited,
  &:focus,
  &:focus-visible {
    outline: 2px solid $accme-blue;
  }
  &:hover {
    color: $accme-blue;
    text-decoration: underline;
  }
  nav & {
    &:hover {
      text-decoration: none;
    }
  }
}

.site-ja a {
  color: $ja-dark-blue;
  &:hover {
    color: $accme-turquoise;
  }
}

a,
button {
  @include button-unstyled;
  &.primary {
    @include button-text;
    align-items: center;
    background: $accme-purple;
    border: 0;
    border-radius: rem(26.5);
    box-sizing: border-box;
    color: $white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-width: rem(180);
    opacity: 1;
    padding: spacing(sm);
    &:hover {
      background-color: $accme-dark-purple;
      color: $white;
      text-decoration: none;
    }
    + .tertiary {
      margin-top: spacing(md);
    }
    &:disabled {
      background-color: transparentize($lighter-gray, 0.2);
      color: transparentize($dark-gray, 0.1);
      cursor: unset;
    }
  }
  &.secondary {
    @include button-text;
    align-items: center;
    background: $white;
    border: rem(2) solid $accme-purple;
    border-radius: rem(26.5);
    box-sizing: border-box;
    color: $accme-purple;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-width: rem(90);
    opacity: 1;
    padding: rem(14);
    &:hover {
      background: $white;
      border-color: $accme-dark-purple;
      color: $accme-dark-purple;
    }
    &:disabled {
      background-color: transparentize($lighter-gray, 0.2);
      border-color: $lighter-gray;
      color: transparentize($dark-gray, 0.1);
      cursor: unset;
    }
  }
  &.tertiary {
    @include button-text;
    align-items: center;
    color: $accme-dark-blue;
    display: inline-flex;
    letter-spacing: rem(0.5);
    padding: 0;
    .site-ja & {
      color: $ja-blue;
    }
    &--small {
      @include body-s;
      letter-spacing: rem(0.44);
    }
    .tertiary-icon {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      margin: 0 0 0 spacing(xs);
      transform: rotate(180deg);
      &-back {
        margin: 0 spacing(sm) 0 0;
        transform: rotate(0deg);
      }
    }
    &:hover {
      color: $accme-dark-blue;
      cursor: pointer;
      .site-ja & {
        color: $accme-turquoise;
      }
    }
  }
  &.link {
    @include body-m;
    color: $accme-dark-blue;
    letter-spacing: rem(0.5);
    text-align: left;
    text-decoration: underline;
    &:hover {
      color: $accme-dark-blue;
      cursor: pointer;
    }
    &--hyperlink {
      @include body-s;
      letter-spacing: rem(0.44);
    }
    .site-ja & {
      color: $ja-dark-blue;
      &:hover {
        color: $accme-turquoise;
      }
    }
  }
  &.icon {
    @include body-m;
    align-items: center;
    color: $darkest-gray;
    display: flex;
    margin-top: rem(6);
    svg {
      background: $white;
      border: 0;
      border-radius: 50%;
      box-shadow: 3px 3px 8px 2px $boxshadow;
      fill: $accme-lime-green;
      margin-right: spacing(xs);
      .site-ja & {
        fill: $ja-teal;
      }
    }
    &:hover:active {
      svg {
        box-shadow: none;
      }
    }
    &.ascending {
      svg {
        box-shadow: 3px -1px 8px 2px $boxshadow; // box-shadow needs to remain in lower right quadrant while rotating
        transform: rotate(90deg);
        transform-origin: center;
      }
    }
    &.descending {
      svg {
        box-shadow: -3px 1px 8px 2px $boxshadow; // box-shadow needs to remain in lower right quadrant while rotating
        transform: rotate(270deg);
        transform-origin: center;
      }
    }
    &:hover {
      color: $accme-blue;
    }
  }
  &.label {
    align-items: center;
    color: inherit;
    display: flex;
    font: inherit;
    padding: 0;
    text-decoration: none;
    svg {
      font-size: inherit;
      margin-right: spacing(xs);
    }
    &:hover {
      color: $accme-blue;
      text-decoration: none;
      svg {
        color: initial;
      }
    }
    .site-ja & {
      color: inherit;
      &:hover {
        color: $accme-turquoise;
      }
    }
  }
  &.btn {
    &:visited,
    &:focus,
    &:focus-visible {
      outline: 2px solid $accme-blue;
    }
    &-faq,
    &-actions {
      align-items: center;
      background: $accme-peach;
      border: 0;
      border-radius: rem(24);
      box-sizing: border-box;
      color: $darkest-gray;
      cursor: pointer;
      display: flex;
      font-family: $font-family-sans-serif-body;
      font-size: rem(12);
      letter-spacing: rem(0.25);
      opacity: 1;
      padding: spacing(xxs) spacing(sm);
      .site-ja & {
        background: $ja-orange;
      }
    }
    &-addfilter {
      @include body-s;
      align-items: center;
      background: $white;
      border: rem(0.5) solid $warm-gray;
      border-radius: rem(24);
      box-sizing: border-box;
      color: $darkest-gray;
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin: spacing(md) 0 spacing(sm);
      opacity: 1;
      padding: spacing(xxs) spacing(sm);
      @include breakpoint-max(xs) {
        width: 100%;
      }
      svg {
        margin-right: spacing(xs);
      }
    }
  }
  &.close {
    color: $darkest-gray;
  }
  &.remove {
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-left: spacing(xxs);
    // max-height: rem(30);
    padding: spacing(xxs);
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  &.pill {
    align-items: center;
    background-color: $white;
    border: solid rem(0.5) $lighter-gray;
    border-radius: rem(24);
    color: $darkest-gray;
    display: flex;
    flex-wrap: nowrap;
    font-size: rem(12);
    justify-content: center;
    margin: 0 spacing(xs) spacing(xs) 0;
    padding: spacing(xxs) rem(12);
    white-space: nowrap;
    svg {
      font-size: rem(14);
      margin-left: rem(12);
    }
  }
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    color: $medium-gray;
    cursor: unset;
    svg {
      color: inherit;
    }
  }
}
