@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.dropdown {
  > div {
    width: 100%;
  }
  label {
    color: $white;
  }
}

:global {
  .mobile {
    &-nav {
      min-height: rem(73);
      overflow: hidden;
      position: relative;
      :global(.site-ja) & {
        button.btn-search--toggle {
          background-color: $ja-teal;
        }
        .expanded-search-container {
          button.btn-search {
            background-color: $ja-teal;
          }
        }
        .mobile-main {
          .user {
            background-color: $ja-blue;
          }
        }
        .mobile-sub {
          button.tertiary {
            color: $ja-blue;
          }
        }
      }
      .open {
        &-enter {
          transform: translateX(100%);
        }
        &-enter-active {
          transform: translateX(0%);
          transition: transform 0.3s ease-in-out;
        }
        &-exit {
          transform: translateX(0%);
        }
        &-exit-active {
          transform: translateX(100%);
          transition: transform 0.3s ease-in-out;
        }
      }
    }
    &-header {
      @include thin-border;
      align-items: center;
      background-color: $lightest-gray;
      display: flex;
      justify-content: space-between;
      min-height: rem(73);
      padding: 0 spacing(sm);
    }
    &-menu-control {
      button {
        @include button-flex;
      }
    }
    &-logo {
      display: flex;
      justify-content: center;
      max-width: rem(162);
    }
    &-main {
      height: 0;
      overflow: hidden;
      transform-origin: top;
      transition: height 0.3s ease-out;
      &.open {
        height: calc(100vh - #{rem(73)});
        overflow: auto;
      }
      .menu {
        @include list-unstyled;
        position: relative;
        .menu-btn {
          @include thin-border;
          @include font-weight(semibold);
          align-items: center;
          color: $darkest-gray;
          display: flex;
          font-family: $font-family-sans-serif-body;
          font-size: rem(16);
          justify-content: space-between;
          line-height: line-height(22, 16);
          padding: rem(15) spacing(sm);
          text-transform: none;
          width: 100%;
          &:last-child {
            &::after {
              display: initial;
            }
          }
        }
      }
      .user {
        background-color: $accme-blue;
        color: $white;
        padding: spacing(lg) spacing(sm) rem(86);
        a,
        button {
          @include font-weight(semibold);
          color: $white;
          display: block;
          font-size: rem(14);
          line-height: line-height(26, 14);
        }
        button {
          margin: spacing(xxxl) spacing(sm) 0 auto;
        }
        &-info {
          @include list-unstyled;
          @include thin-border;
          margin: spacing(sm) 0;
          padding: 0 0 spacing(md);
          li {
            @include body-s;
            letter-spacing: normal;
            margin-bottom: spacing(xxs);
            &:last-child {
              margin-bottom: 0;
            }
          }
          &::after {
            border-color: $white;
          }
        }
      }
    }
    &-sub {
      background-color: $lightest-gray;
      height: calc(100vh - #{rem(73)});
      overflow: hidden;
      position: absolute;
      top: 0;
      transform-origin: right;
      width: 100%;
      z-index: 10;
      button.tertiary {
        background-color: $white;
        font-size: rem(14);
        padding: spacing(sm);
        width: 100%;
        svg {
          font-size: rem(16);
          margin-right: spacing(sm);
        }
      }
      .menu {
        color: $darkest-gray;
        padding: 0 spacing(sm);
        &-title {
          @include font-weight(semibold);
          font-size: rem(16);
          margin: rem(20) 0 spacing(md);
        }
        ul {
          @include list-unstyled;
          li {
            margin-bottom: spacing(md);
            a {
              align-items: center;
              color: $darkest-gray;
              display: flex;
              font-size: rem(14);
              line-height: line-height(26, 14);
            }
            svg {
              margin-right: rem(14);
              padding: spacing(xxs);
            }
          }
        }
      }
    }
  }
}
