@import 'src/globals/scss/theme';

.editor {
  background: $white;
  > div:last-child {
    > div {
      min-height: 200px;
    }
  }
}
