@import 'src/globals/scss/theme';
@import 'src/globals/scss/base';

.highlight-box {
  background: $white;
  box-shadow: 5px 5px 10px $boxshadow-alt;
  margin: rem(20) rem(5);
  padding: rem(10);
  p {
    color: $accme-dark-blue;
    font-style: italic;
    margin: 0;
  }
  &.danger {
    background: transparentize($accme-red, 0.15);
    p {
      color: $white;
    }
  }
  &.warning {
    background: transparentize($accme-peach, 0.3);
    p {
      color: $darkest-gray;
    }
  }
  span {
    font-weight: 600;
  }
}
