//Primary Colors
$accme-blue: #007bbd;
$accme-dark-blue: #0a6799;
$accme-lime-green: #82bc00;
$accme-olive: #62695e;
$accme-turquoise: #00838f;
$ja-blue: #00748c;
$ja-teal: #00aea9;
$ja-dark-blue: #004d58;

//Secondary Colors
$accme-peach: #ef8f7a;
$accme-purple: #854a94;
$accme-dark-purple: #663573;
$accme-red: #bd2e2a;
$accme-yellow: #fec600;
$ja-orange: #f68e56;

//Grays & Neutrals
$boxshadow: #00000019;
$boxshadow-alt: #00000014;
$darkest-gray: #373737;
$darkest-gray-50: #37373780;
$dark-gray: #4d4d4f;
$medium-dark-gray: #717070;
$medium-gray: #949494;
$lighter-gray: #dedede;
$lightest-gray: #f8f8f8;
$warm-gray: #dfdcd9;
$other-gray: #b9b9b9;
$light-warm-gray: #f2f1f0;
$pale-blue: #dee9ee;
$palest-blue: #e8f0f4;
$pale-teal: #d8e6e5;
$palest-teal: #f1f7f7;
$white: #fff;
