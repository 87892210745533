@import 'globals/scss/theme';

.container {
  background-color: $white;
  border-radius: 1.5rem;
  height: 250px;
  padding: 1rem;

  > div {
    height: 100%;
  }

  :global {
    .highcharts-container {
      height: 100% !important;
      overflow: visible !important;
      width: 100% !important;
    }
  }
}
