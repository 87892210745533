@import 'globals/scss/mixins';
@import 'globals/scss/functions';
@import 'globals/scss/theme';

.container {
  background-color: $pale-blue;
  display: grid;
  gap: 1.5rem;
  grid-auto-rows: min-content;
  padding: 1rem 1.75rem;
  width: 100%;

  @include breakpoint(sm) {
    padding: 2rem 4.75rem;
  }

  .link {
    @include font-weight(bold);
    align-items: center;
    display: inline-flex;

    svg {
      font-size: 1em;
      margin-right: 1em;
    }
  }
}

.tableHeader th {
  font-weight: 700;
}

.detail-group {
  background-color: $pale-blue;
  border-radius: rem(24);
  margin: 0 0 spacing(md);
  padding: spacing(lg) spacing(sm) spacing(xl);
  position: relative;
  @include breakpoint(sm) {
    padding: spacing(lg) spacing(lg) spacing(xl) rem(36);
  }
  @include breakpoint(md) {
    padding: spacing(lg) rem(44) spacing(xl) rem(44);
  }
  @include breakpoint(lg) {
    padding: spacing(lg) spacing(xxl) spacing(xl) rem(44);
  }
  &-rems {
    margin: 0 0 spacing(lg);
  }
  &-title {
    padding-top: 0;
  }
  &-label {
    margin: 0 0 spacing(sm);
    + .card {
      margin-top: spacing(lg);
    }
  }
  .detail-content {
    color: $darkest-gray;
    &-label {
      @include caption-text;
      margin-bottom: spacing(xxs);
      @include breakpoint(sm) {
        margin-bottom: 0;
      }
    }
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-content: center;
  @include breakpoint(md) {
    flex-direction: row;
  }
  justify-content: space-between;
  .refresh {
    margin: spacing(sm) 0 0 0;
    @include breakpoint(md) {
      margin-top: 0;
    }
    button {
      @include font-size(12);
      padding: rem(10);
    }
  }
}

.table {
  width: auto;
  max-width: 100%;
}
