@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.history {
  .loading-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 1rem;
  }

  header,
  section {
    color: $dark-gray;
    margin-bottom: 2rem;

    h2,
    h3 {
      @include subheading-1;
      @include font-weight(semibold);
      margin: 0;
    }

    p {
      font-size: 0.9rem;
      margin: 0.3rem 0;
      white-space: pre-line;

      strong {
        @include font-weight(semibold);
      }
    }
  }

  header {
    font-size: 0.9rem;
  }

  footer {
    display: flex;
    justify-content: flex-end;
  }
}
