@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.widget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section {
  h2 {
    @include heading-4;
    align-items: center;
    display: inline-flex;

    .icon {
      color: $accme-peach;
      margin-right: 0.5rem;
    }
  }
}

.footer {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  * + * {
    margin-left: 2rem;
  }

  :global {
    a:not(.primary) {
      color: $accme-dark-blue;
    }
  }
}
