@import 'globals/scss/mixins';

.banners {
  background-color: $accme-turquoise;
  color: $white;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
    }
  }
}
