@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.form {
  margin-top: spacing(sm);
  .required {
    color: $accme-red;
  }
}

.completion-input {
  margin: 0 0 spacing(xs) 0;
}

.input-label {
  @include eyebrow;
  color: $accme-olive;
  margin-bottom: 0;
}

.learner {
  @include body-m;
  color: $darkest-gray;
  margin: 0;
}

.total-credits {
  &-input {
    margin-bottom: spacing(md);
  }
  &-label {
    font-family: $font-family-sans-serif-body;
  }
}

.board-credits {
  &-input {
    margin-top: spacing(md);
    > label {
      margin: 0;
    }
  }
}

.button-row {
  margin: spacing(md) 0;
  .submit-button-container > button {
    width: 100%;
    margin-top: spacing(xs);
    @include breakpoint(sm) {
      margin-top: 0;
    }
  }
}

.error {
  margin: 0 0 spacing(sm) 0;
}

// The TextField component has a built-in FormHelperText component that renders errors
// There's no built-in way to permanently hide it, hence this override
.hide-helper-text {
  display: none;
}
