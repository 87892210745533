@import 'src/globals/scss/variables';

.submit-button-container {
  margin: 1rem 0;
}

/** need 3 levels of specificity to override the default styles */
.disabled.disabled.disabled input {
  background: $lighter-gray;
}

.address {
  margin: 1rem 0;
}

.checkbox-group {
  margin: 1rem 0;
}
