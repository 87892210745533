@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

:global {
  .accordion {
    list-style: none;
    max-width: 100%;
    padding-left: 0;

    &-item {
      overflow: visible;

      &-heading {
        @include button-unstyled;
        width: 100%;
      }

      &-title {
        align-items: center;
        border-bottom: rem(0.3) solid $lighter-gray;
        display: flex;
        font-family: $font-family-sans-serif-body;
        font-size: rem(16);
        justify-content: space-between;
        line-height: line-height(26);
        padding: rem(10) 0;

        svg {
          border: 0;
          border-radius: 50%;
          box-shadow: 1px 3px 8px 2px $boxshadow;
          fill: $accme-lime-green;
          :global(.site-ja) & {
            fill: $ja-teal;
          }
        }
      }

      &-content {
        display: none;
        table,
        th,
        td {
          border: 1px solid $darkest-gray-50;
          border-collapse: collapse;
        }
        th,
        td {
          padding: rem(5);
          text-align: left;
        }
      }

      &--active {
        .accordion-item-content {
          display: block;
          font-size: rem(14);
          line-height: line-height(22, 14);
          padding-bottom: rem(14);
        }
      }
    }
  }
}
