@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

:global {
  .usercontrol {
    @include font-size(14);
    @include font-weight(semibold);
    align-items: center;
    background-color: $accme-blue;
    color: $white;
    display: none;
    justify-content: flex-end;
    padding: rem(6) rem(76);
    position: relative;
    @include breakpoint(sm) {
      display: flex;
    }
    a,
    a:link,
    a:visited {
      color: inherit;
      &:hover {
        color: inherit;
      }
    }
    &-action {
      margin-right: rem(32);
    }
    &-user {
      a {
        align-items: center;
        display: flex;
        justify-content: space-between;

        svg {
          font-size: rem(20);
        }
      }
    }
    &-notifications {
      .badge {
        @include font-weight(semibold);
        align-items: center;
        background-color: $accme-red;
        border-radius: 50%;
        display: flex;
        font-size: rem(9);
        height: rem(15.5);
        justify-content: center;
        margin-left: rem(7);
        padding: rem(0.5);
        width: rem(15.5);
      }
      &:hover {
        text-decoration: none;
      }
    }
    .menu {
      background-color: $white;
      box-shadow: 1px 3px 8px 2px $boxshadow;
      color: $darkest-gray;
      display: none;
      padding: rem(22) rem(35);
      position: absolute;
      top: 34px;
      z-index: 1;
      &.active {
        display: block;
        min-width: 400px;
      }
      .user-info {
        border-bottom: 1px solid $medium-gray;
        color: $darkest-gray;
        font-size: rem(12);
        line-height: 1.67;
        margin-bottom: rem(15);
        padding-bottom: rem(20);
        &.dropdown-controls {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
        &:empty {
          display: none;
        }
        > div {
          width: 100%;
        }
      }
      &-utility {
        display: flex;
        flex-direction: column;
        .support-link {
          @include font-weight(semibold);
          color: $darkest-gray;
          font-size: rem(14);
          line-height: 1.86;
        }
        .in-menu {
          justify-content: flex-end;
        }
      }
    }
  }
}
