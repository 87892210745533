@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

.error {
  background: $accme-dark-blue;
  color: $white;
  padding: spacing(xs) spacing(sm);
  span.message {
    font-weight: 600;
    line-height: 1.57;
    margin-left: spacing(sm);
  }
}

.dropzone {
  align-items: center;
  border: 2px dashed $medium-gray;
  border-radius: rem(2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: rem(160);
  padding: rem(10);
  p {
    font-weight: 600;
    span {
      color: $accme-blue;
      cursor: pointer;
    }
  }
}

[role='progressbar'] {
  svg {
    color: $accme-blue;
  }
}
