@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

:global {
  input.pill[type='checkbox'] {
    display: none;
    height: 0;
    visibility: hidden;
    width: 0;
    + label {
      background-color: $white;
      border: solid rem(0.5) $lighter-gray;
      border-radius: rem(24);
      color: $darkest-gray;
      cursor: pointer;
      display: inline-block;
      font-size: rem(14);
      line-height: line-height(22, 14);
      margin-right: spacing(sm);
      overflow: hidden;
      padding: rem(6) spacing(xs);
      text-align: center;
      text-indent: 0;
      white-space: nowrap;
    }
    &:checked + label {
      background-color: $accme-blue;
      color: $white;
    }
  }
}
