@import 'src/globals/scss/extensions';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/theme';
@import 'src/globals/scss/variables';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  color: $dark-gray;
  font-size: rem(12);
  font-weight: 600;
  letter-spacing: rem(1.5);
  line-height: 1.66667;
  margin-bottom: spacing(xs);
  text-transform: uppercase;

  .required {
    color: $accme-red;
    margin-left: 5px;
  }
}

.input {
  @include input-styles('%input-styles', '%input-styles--focus', '%input-styles--error', '%input-styles--disabled');
  width: 100%;

  &.offset {
    margin-top: 25.5px;
  }
}
