@import 'globals/scss/mixins';
@import 'globals/scss/theme';

:global {
  .report-style-class {
    height: 100vh;
    width: 99vw;
  }

  .report-listing {
    margin: 5px;
  }
  
  .download-all-button {
    @include subheading-1;
    @include font-weight(semibold);
    display: flex;
    align-items: center;
    color: $white;
    margin-left: auto;
  }

  .download-all-button:hover {
    color: $white;
  }
}
