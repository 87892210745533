@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.label {
  @include body-s;
  display: block;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;

  > * {
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
}

.file {
  align-items: center;
  border: 1px solid $other-gray;
  border-radius: 1rem;
  display: inline-flex;
  line-height: 1;
  padding: 0.875rem;

  &.error {
    border-color: $accme-red;
  }

  .button {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin-left: 1rem;

    svg {
      height: 1.25rem;
    }
  }
}

.message {
  font-size: 0.8rem;

  &.error {
    color: $accme-red;
  }
}

.input {
  display: none;
}
