@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

.search-buttons {
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
}
.paging-buttons {
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
  a,
  button {
    font-size: 1rem;
    padding: 0.5rem;
    min-width: 3rem;
    margin: 0.25rem;
  }
}

.previousArrow {
  padding-left: 5px;
}

.nextArrow {
  padding-left: 4px;
}

.ellipsis {
  margin-top: 12px;
}

.tableHeader th {
  font-weight: 700;
}

.table {
  width: auto;
  max-width: 100%;

  .width-restricted-cell {
    width: 180px;
    max-width: 180px;
    white-space: nowrap;
  }
}

.action-buttons {
  display: flex;
  a,
  button {
    font-size: 0.75rem;
    padding: 0.5rem;
    min-width: 3rem;
    margin: 0.25rem;
  }
}

.is-primary-contact {
  align-items: center;
  background-color: $white;
  border: solid rem(0.5) $lighter-gray;
  border-radius: rem(24);
  color: $darkest-gray;
  display: flex;
  flex-wrap: nowrap;
  font-size: rem(12);
  justify-content: center;
  margin: 0 spacing(xs) spacing(xs) 0;
  padding: spacing(xxs) rem(12);
  white-space: nowrap;
}

.modal-footer {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0 0;
}
