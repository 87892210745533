@import 'globals/scss/mixins';
@import 'globals/scss/functions';
@import 'globals/scss/theme';

.container {
  background-color: $pale-blue;
  display: grid;
  gap: 1.5rem;
  grid-auto-rows: min-content;
  padding: 1rem 1.75rem;
  width: 100%;

  @include breakpoint(sm) {
    padding: 2rem 4.75rem;
  }

  .link {
    @include font-weight(bold);
    align-items: center;
    display: inline-flex;

    svg {
      font-size: 1em;
      margin-right: 1em;
    }
  }
}

.error {
  background: $accme-dark-blue;
  color: $white;
  padding: spacing(xs) spacing(sm);
  span.message {
    font-weight: 600;
    line-height: 1.57;
    margin-left: spacing(sm);
  }
}
.dropzone {
  align-items: center;
  border: 2px dashed $medium-gray;
  border-radius: rem(2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: rem(160);
  padding: rem(10);
  p {
    font-weight: 600;
    span {
      color: $accme-blue;
      cursor: pointer;
    }
  }
}
