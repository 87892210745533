@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.header {
  align-items: center;
  background-color: $accme-turquoise;
  color: $white;
  display: flex;
  padding: 0.75rem 1.5rem;

  .icon {
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    box-shadow: 1px 3px 8px 2px $boxshadow;
    color: $accme-lime-green;
    display: inline-flex;
    height: 2.5rem;
    justify-content: center;
    margin-right: 1rem;
    width: 2.5rem;
  }

  .title {
    @include subheading-1;
    @include font-weight(semibold);
  }

  .blue & {
    background-color: $accme-blue;
  }

  .purple & {
    background-color: $accme-purple;
  }
}

.content {
  @include body-m;
  background-color: transparentize($accme-turquoise, 0.75);
  column-gap: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  padding: 1.5rem;
  row-gap: 1.5rem;

  @include breakpoint(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(md) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.empty {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .report {
    @include body-m;
    background-color: $white;
    border-radius: 1.5rem;
    box-shadow: 1px 3px 8px 2px $boxshadow;
    padding: 1.5rem;

    .subtitle {
      @include subheading-1;
      @include font-weight(semibold);
    }
  }

  .blue & {
    background-color: transparentize($accme-blue, 0.75);
  }

  .purple & {
    background-color: transparentize($accme-purple, 0.75);
  }
}
