@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

.learner-name {
  font-size: rem(16);
  font-weight: 600;
  margin-bottom: spacing(xs);
}

.row {
  &--pipe-separated {
    font-size: rem(14);
    > * {
      &::after {
        content: '|';
        margin: 0 spacing(xs);
      }
      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }
}

.question {
  font-weight: 600;
  margin: spacing(lg) 0;
}
