@import 'src/globals/scss/variables';
@import 'src/globals/scss/mixins/typography';

@mixin color-pill {
  border-radius: 5px;
  color: $white;
  padding: 0 rem(10);
}

.label-text {
  &.--draft,
  &.--rejected {
    @include color-pill;
    background-color: $accme-red;
  }
  &.--active,
  &.--accepted {
    @include color-pill;
    background-color: $accme-turquoise;
  }
  &.--ready-to-close,
  &.--submitted,
  &.--delete-submitted {
    @include color-pill;
    background-color: $accme-dark-purple;
  }
  &.--closed,
  &.--not-processed,
  &.--delete-not-processed {
    @include color-pill;
    background-color: $medium-dark-gray;
  }
}
