@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.loading-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 1rem;
}

.required {
  color: $accme-red;
}

.section {
  .title {
    color: $darkest-gray;
    font-size: rem(16);
    font-weight: 600;
    margin: 0 0 1rem;
  }

  & + & {
    border-top: 1px solid $lighter-gray;
    margin-top: 3rem;
    padding-top: 3rem;
  }

  p {
    font-size: 0.9rem;
  }

  .select {
    width: 100%;
  }
}
