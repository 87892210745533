@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

:global {
  .stepper {
    @include list-unstyled;
    display: flex;
    margin: 0 0 rem(44) rem(-28);
    width: 100%;
    > li {
      @extend %remain-steps;
      align-items: center;
      color: $accme-dark-blue;
      counter-increment: stepNum;
      display: flex;
      flex-direction: column;
      max-width: rem(109);
      position: relative;
      text-align: center;
      width: 100%;
      :global(.site-ja) & {
        color: $dark-gray;
      }
      &::before {
        align-items: center;
        background-color: $accme-blue;
        color: $white;
        display: flex;
        height: rem(32);
        justify-content: center;
        margin: 0 auto rem(8);
        text-align: center;
        width: rem(32);
        @include breakpoint-max(sm) {
          height: rem(10);
          width: rem(10);
        }
        border: {
          color: $accme-blue;
          radius: 50%;
          style: solid;
          width: rem(0.3);
        }
        :global(.site-ja) & {
          background-color: $ja-dark-blue;
          border-color: $ja-dark-blue;
        }
      }
      &::after {
        background-color: $white;
        content: '';
        height: 2px;
        left: 50%;
        margin-left: rem(23);
        position: absolute;
        top: 16px;
        width: calc(100% - 46px);
        @include breakpoint-max(sm) {
          margin-left: rem(8);
          top: rem(4);
          width: calc(100% - 16px);
        }
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      &:hover {
        cursor: pointer;
      }
      &.active {
        @extend %remain-steps;
        &::before {
          background-color: $accme-lime-green;
          border-color: $accme-lime-green;
          :global(.site-ja) & {
            background-color: $ja-teal;
            border-color: $ja-teal;
          }
        }
        ~ li {
          @extend %remain-steps;
          &::before {
            background-color: $white;
            border-color: $medium-gray;
            color: $medium-gray;
          }
          &:hover {
            cursor: default;
          }
        }
      }
      &.disabled {
        @extend %remain-steps;
        &:hover {
          cursor: default;
        }
        &::before {
          background-color: $white;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23949494' stroke-width='0.3' stroke-dasharray='4' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
          border: 0;
          color: $lighter-gray !important;
          @include breakpoint-max(sm) {
            background-image: none;
          }
        }
      }
      &.completed {
        &::before {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 100%'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23FFF'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M11.316 16.18l-2.774-2.774c-.31-.31-.8-.31-1.11 0-.31.309-.31.8 0 1.11l3.322 3.321c.309.31.808.31 1.117 0l8.404-8.395c.309-.31.309-.8 0-1.11-.31-.31-.801-.31-1.11 0l-7.849 7.848z' transform='translate(-665 -240) translate(39 238) translate(585) translate(41 2)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          @include breakpoint-max(sm) {
            content: '';
          }
        }
      }
    }
  }
}
