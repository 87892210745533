@import 'src/globals/scss/forms';
@import 'src/globals/scss/theme';

%fieldset {
  border: 0;
  border-bottom: 0.3px solid $other-gray;
  color: $darkest-gray;
  font-family: $font-family-sans-serif-header;
  margin: spacing(sm) 0;
  padding: 0 0 spacing(md);

  @include breakpoint(sm) {
    margin: spacing(md) 0;
  }

  &:not(:first-of-type) {
    margin-top: spacing(md);
  }

  legend {
    font-size: rem(20);
    line-height: 1.3;
    margin-bottom: spacing(sm);

    @include breakpoint(sm) {
      margin-bottom: spacing(md);
    }

    button {
      font-size: inherit;
      text-decoration: none;
    }
  }

  &.no-divider {
    border: 0;
  }

  :global {
    .form-input {
      width: 100%;
    }
  }
}

.form-card {
  background-color: $pale-blue;
  border-radius: rem(24);
  margin-bottom: spacing(md);
  padding: rem(12) spacing(md) spacing(sm);

  @include breakpoint(sm) {
    padding: rem(22) spacing(xl) spacing(md);
  }

  > fieldset {
    @extend %fieldset;
  }

  &.success-page {
    svg {
      color: $accme-dark-blue;
      font-size: rem(40);
    }
  }
}

.form-card-banner {
  background-color: $pale-blue;
  border-radius: rem(24);
  margin-bottom: spacing(md);
  padding-bottom: rem(22);
  section {
    > fieldset {
      @extend %fieldset;
      margin: 0;
    }
  }
  .banner {
    background: $white;
    border: 0;
    border-bottom: 0.3px solid $other-gray;
    border-top-left-radius: rem(24);
    border-top-right-radius: rem(24);
    color: $darkest-gray;
    font-family: $font-family-sans-serif-header;
    font-size: rem(18);
    padding: spacing(md) spacing(xl);
    label {
      margin: 0; // This is an override for the _form.scss.
    }
  }

  .container {
    padding: spacing(md) spacing(xl);
  }
}

.required {
  color: $accme-red;
}

.input-block {
  :global {
    .error {
      margin: 3px 0 0;
    }
  }
}
