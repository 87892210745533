@import 'globals/scss/mixins';
@import 'globals/scss/functions';
@import 'globals/scss/theme';

.container {
  background-color: $pale-blue;
  display: grid;
  gap: 1.5rem;
  grid-auto-rows: min-content;
  padding: 1rem 1.75rem;
  width: 100%;

  @include breakpoint(sm) {
    padding: 2rem 4.75rem;
  }

  .link {
    @include font-weight(bold);
    align-items: center;
    display: inline-flex;

    svg {
      font-size: 1em;
      margin-right: 1em;
    }
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-content: center;
  @include breakpoint(md) {
    flex-direction: row;
  }
  justify-content: space-between;
  .refresh {
    margin: spacing(sm) 0 0 0;
    @include breakpoint(md) {
      margin-top: 0;
    }
    button {
      @include font-size(12);
      padding: rem(10);
    }
  }
}
