@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.learner-detail {
  &-title-container {
    .title {
      width: 100%;
      h4 {
        margin-bottom: spacing(sm);
        @include breakpoint(lg) {
          display: flex;
          justify-content: space-between;
        }
        .learner-dob {
          @include body-m;
          @include font-weight(regular);
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .activity-title {
      @include body-s;
      @include font-weight(semibold);
    }
    .activity-meta {
      @include body-s;
      word-wrap: break-word;
      span {
        white-space: nowrap;
        &:first-child {
          &::after {
            content: '|';
            padding: 0 spacing(xxs);
          }
        }
        &:last-child {
          @include breakpoint(sm) {
            &::before {
              content: '|';
              padding: 0 spacing(xxs);
            }
          }
        }
      }
    }
  }
  &-section {
    background-color: $pale-blue;
    border-radius: rem(24);
    margin: 0 0 spacing(md);
    padding: spacing(lg) spacing(sm) spacing(xl);
    position: relative;
    @include breakpoint(sm) {
      padding: spacing(lg) spacing(lg) spacing(xl) rem(36);
    }
    @include breakpoint(md) {
      padding: spacing(lg) rem(44) spacing(xl) rem(44);
    }
    @include breakpoint(lg) {
      padding: spacing(lg) spacing(xxl) spacing(xl) rem(44);
    }
  }
}
