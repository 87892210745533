@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.account-manager-title {
  .organization-subheader {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include breakpoint(lg) {
      justify-content: space-between;
    }
    @include breakpoint(sm) {
      flex-direction: row;
    }
    .organization-id-list {
      list-style-type: none;
      padding: 0;
      li {
        margin: 0;
      }
      + .status-indicator {
        margin: spacing(sm) 0 0 0;
        @include breakpoint(sm) {
          margin-left: spacing(sm);
          margin-top: 0;
        }
      }
    }
  }
}

.white-border {
  border: 1px solid $white;
}

.field-error {
  color: $accme-red;
  font-family: $font-family-sans-serif-body;
}

.detail-group {
  background-color: $pale-blue;
  border-radius: rem(24);
  margin: 0 0 spacing(md);
  padding: spacing(lg) spacing(sm) spacing(xl);
  position: relative;
  @include breakpoint(sm) {
    padding: spacing(lg) spacing(lg) spacing(xl) rem(36);
  }
  @include breakpoint(md) {
    padding: spacing(lg) rem(44) spacing(xl) rem(44);
  }
  @include breakpoint(lg) {
    padding: spacing(lg) spacing(xxl) spacing(xl) rem(44);
  }
  &-rems {
    margin: 0 0 spacing(lg);
  }
  &-title {
    padding-top: 0;
  }
  &-label {
    margin: 0 0 spacing(sm);
    + .card {
      margin-top: spacing(lg);
    }
  }
  .detail-content {
    color: $darkest-gray;
    ul {
      @include list-unstyled;
    }
    &-label {
      @include caption-text;
      margin-bottom: spacing(xxs);
      @include breakpoint(sm) {
        margin-bottom: 0;
      }
    }
    &-value {
      @include body-s;
      margin-bottom: spacing(sm);
      word-break: break-word;
      @include breakpoint(sm) {
        @include body-m;
      }
    }
    &.incomplete {
      .detail-content-label {
        &::after {
          color: $accme-red;
          content: '*';
          margin-left: spacing(xxs);
        }
      }
      .detail-content-value {
        color: $accme-red;
      }
    }
    &.card {
      border: rem(1) solid $white;
      border-radius: rem(24);
      margin-bottom: spacing(md);
      padding: 0 rem(30) spacing(md);
      .card-title {
        @include body-l;
        background: $white;
        border-top-left-radius: rem(24);
        border-top-right-radius: rem(24);
        margin: 0 rem(-30) spacing(sm);
        padding: spacing(xs) rem(30);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  li {
    &.detail-content-value {
      margin-bottom: 0;
      &:last-child {
        margin-bottom: spacing(md);
      }
    }
  }
  p {
    &.detail-content-value {
      margin: 0 0 spacing(md);
    }
  }
  .secondary {
    button {
      width: 100%;
      @include breakpoint(sm) {
        width: auto;
        position: absolute;
        right: rem(44);
        top: spacing(md);
      }
    }
  }
}
