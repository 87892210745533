@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.summary {
  @include body-m;
  @include font-weight(semibold);
}

.list {
  @include body-m;
  list-style: none;
  margin: 0;
  padding: 0;

  strong {
    @include font-weight(semibold);
  }

  li + li {
    margin-top: 1rem;
  }
}

.icon {
  background-color: $white;
  border-radius: 50%;
  box-shadow: 1px 0 8px 2px $boxshadow;
  color: $accme-lime-green;
  height: rem(32);
  padding: rem(5);
  width: rem(32);
}
