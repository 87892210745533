@import 'functions';
@import 'mixins';
@import 'variables';

%search-box {
  align-items: center;
  background-color: $white;
  border: rem(0.5) solid $medium-gray;
  border-radius: rem(20);
  display: flex;
  height: rem(40);
  justify-content: space-between;
  position: relative;
  width: rem(264);
  &:focus-within {
    border: rem(2) solid $accme-blue;
  }
  .site-ja & {
    &:focus-within {
      border: rem(2) solid $accme-turquoise;
    }
  }
  input {
    background: transparent;
    border: 0;
    padding: rem(10) rem(22);

    &::placeholder {
      color: $medium-gray;
    }
  }
}
%input-styles {
  align-items: center;
  background: $white;
  border: rem(0.3) solid $lighter-gray;
  border-radius: rem(12);
  box-shadow: inset 0 1px 3px 0 #00000014;
  color: $darkest-gray;
  display: flex;
  font-family: $font-family-sans-serif-body;
  font-size: rem(16);
  line-height: line-height(26);
  min-height: rem(55);
  padding: 0 spacing(sm);
  width: stretch;
  width: available;
  &::placeholder {
    color: $medium-gray;
  }
  &[readonly] {
    background: $lighter-gray;
    cursor: not-allowed;
  }
}

%input-styles-nowidth {
  align-items: center;
  background: $white;
  border: rem(0.3) solid $lighter-gray;
  border-radius: rem(12);
  box-shadow: inset 0 1px 3px 0 #00000014;
  color: $darkest-gray;
  display: flex;
  font-family: $font-family-sans-serif-body;
  font-size: rem(16);
  line-height: line-height(26);
  min-height: rem(55);
  padding: 0 spacing(sm);
  &::placeholder {
    color: $medium-gray;
  }
}

%input-styles--focus {
  background-color: $white;
  border: rem(2) solid $accme-blue;
  .site-ja & {
    border: rem(2) solid $accme-turquoise;
  }
}

%input-styles--error {
  border: rem(2) solid $accme-red;
  color: $accme-red;
  &::placeholder {
    color: $accme-red;
  }
  &:focus {
    color: $darkest-gray;
    &::placeholder {
      color: $medium-gray;
    }
  }
}

%input-styles--disabled {
  background-color: $lighter-gray;
  box-shadow: none;
}

%radio-styles {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  margin-bottom: spacing(sm);
  position: relative;
  input {
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    & + span::before {
      margin-bottom: 1px;
      margin-left: 1px;
      outline: transparent solid 1px;
    }
    &:focus + span::before {
      outline: $accme-blue solid 1px;
    }
    &:checked + span {
      &::before {
        background-color: $accme-lime-green;
        box-shadow: inset 0 0 0 rem(3.5) $white;
        .disabled & {
          background-color: $lighter-gray;
        }
        .site-ja & {
          background-color: $ja-teal;
        }
      }
    }
    &:disabled:checked + span {
      &::before {
        background-color: $other-gray;
      }
      div {
        color: $other-gray;
      }
    }
    &:disabled + span {
      div {
        color: $other-gray;
      }
    }
  }
  span {
    @include body-m;
    align-items: flex-start;
    border-radius: 50%;
    color: $darkest-gray;
    display: flex;
    flex-basis: 100%;
    &::before {
      background-color: $white;
      border: rem(0.3) solid $lighter-gray;
      border-radius: 50%;
      box-shadow: inset 0 rem(1) rem(3) 0 #00000014;
      content: '';
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      height: rem(24);
      margin: rem(2) rem(10) 0 0;
      width: rem(24);
    }
    .label-text {
      margin-bottom: rem(2);
    }
  }
  + .form-input {
    margin-left: rem(34);
  }
}

%checkbox-styles {
  @extend %radio-styles;
  span {
    border-radius: 0;
    &::before {
      border-radius: 0;
    }
  }
}

%status {
  @include font-weight(semibold);
  align-items: center;
  background: $medium-dark-gray;
  border-radius: rem(8);
  color: $white;
  display: flex;
  font-size: rem(12);
  height: rem(24);
  justify-content: center;
  line-height: line-height(22, 12);
  margin-top: rem(2);
  padding: 0 spacing(xs) 0;
  text-transform: capitalize;
  .label-text {
    color: inherit;
  }
}
%alert {
  align-items: center;
  background: $accme-red;
  color: $white;
  display: flex;
  font-size: rem(14);
  justify-content: space-between;
  line-height: line-height(22, 14);
  margin: 0 0 spacing(md);
  padding: spacing(xs) spacing(md) spacing(xs) spacing(xs);
  .message {
    align-items: center;
    color: $white;
    display: flex;
    svg {
      margin: 0 rem(15) 0 rem(7);
    }
  }
  a {
    color: inherit;
    font-size: rem(12);
    line-height: line-height(20, 12);
    text-decoration: underline;
  }
}
%remain-steps {
  color: $dark-gray;
  font-size: rem(12);
  line-height: 1.25;
  @include breakpoint-max(sm) {
    font-size: 0;
  }
  &::before {
    @include font-weight(bold);
    content: counter(stepNum);
    font-size: rem(12);
    @include breakpoint-max(sm) {
      content: '';
    }
  }
  &::after {
    background-color: $white;
  }
}

.disable-scroll {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
