@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.button {
  background-color: $white;
  border: rem(1) solid $darkest-gray;
  border-radius: rem(24);
  margin-left: spacing(md);
  > span {
    padding: 0 spacing(sm);
  }
  &:hover {
    background-color: $white;
  }
}

.summary-actions {
  &-options {
    @include body-s;
    @include thin-border;
    color: $darkest-gray;
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: spacing(md);
    padding-bottom: spacing(xs);
    position: relative;
    width: 100%;
    @include breakpoint(sm) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    button {
      @include body-s;
      @include font-weight(semibold);
      color: inherit;
    }
  }
  &-info {
    align-items: center;
    display: flex;
    margin-bottom: spacing(xs);
    @include breakpoint(sm) {
      margin-bottom: 0;
    }
    button {
      @include body-s;
      @include font-weight(semibold);
      color: inherit;
    }
    > :first-child {
      &::after {
        content: '|';
        display: none;
        margin: 0 spacing(sm);
        @include breakpoint(sm) {
          display: inline-flex;
        }
      }
    }
    > :last-child {
      &::after {
        display: none;
      }
    }
  }
  &-utility {
    @include breakpoint(sm) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    button {
      @include font-weight(regular);
    }
    button.update-learners {
      background-color: $white;
      border: rem(1) solid $darkest-gray;
      border-radius: rem(24);
      font: inherit;
      margin: 0;
      padding: 0 spacing(sm);
      position: absolute;
      right: 0;
      top: 0;
      @include breakpoint(sm) {
        margin: 0 0 0 spacing(sm);
        position: relative;
        right: unset;
        top: unset;
      }
      &:hover {
        background-color: $white;
      }
      svg {
        font-size: inherit;
        margin-right: 0.5rem;
      }
    }
  }
  &-controls {
    @include body-s;
    align-items: center;
    color: $darkest-gray;
    justify-content: space-between;
    @include breakpoint(sm) {
      display: flex;
      margin: 0 spacing(md);
      .show-perpage {
        margin-left: -#{spacing(md)};
      }
    }
    button {
      @include body-s;
      @include font-weight(semibold);
      color: inherit;
    }
  }
  &--checkbox {
    @extend %checkbox-styles;
    @include breakpoint(sm) {
      margin: 0;
    }

    // Show teal color when checked and is JA
    input:checked + span.checkmark {
      :global(.site-ja) & {
        &::before {
          background-color: $ja-teal;
        }
      }
    }
    span {
      @include body-s;
      &.checkmark {
        align-items: center;
        margin: 0;
      }
    }
  }
  .show-perpage {
    display: none;
    @include breakpoint(sm) {
      display: block;
    }
  }
  .sort-controls {
    align-items: center;
    display: flex;
    :global(.icon) {
      margin: 0 0 0 spacing(sm);
      svg {
        padding: spacing(xxs);
      }
    }
  }
}
