@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

.field-separator {
  border: none;
  border-top: 1px solid $medium-gray;
  margin: spacing(md) 0;
}
.display-flex {
  display: flex;
}
