@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.learner-search-container {
  margin: spacing(sm) 0 spacing(md);
  overflow: auto;
  padding-bottom: spacing(md);
  width: 100%;
}
.learner-search-table {
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  th,
  td {
    padding: rem(10) rem(6);
    &:first-child,
    &:nth-child(2) {
      position: sticky;
      @include breakpoint(xl) {
        position: relative;
      }
    }
    &:first-child {
      left: 0;
      z-index: 2;
      @include breakpoint(xl) {
        left: unset;
        z-index: unset;
      }
      &.select {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &:nth-child(2) {
      left: rem(24); // width of select column when rendered
      z-index: 1;
      @include breakpoint(sm) {
        left: rem(48); // width of select column when rendered
      }
      @include breakpoint(xl) {
        left: unset;
        z-index: unset;
      }
      &.learner-name {
        left: rem(49); // width of board column when select column isn't rendered
        position: sticky !important; // !important to override .learner-name when it's the second column
      }
    }
    .actions {
      &--checkbox {
        @extend %checkbox-styles;
        margin: 0 0 0 spacing(md);
      }
    }
  }
  th {
    @include font-weight(semibold);
    background-color: $pale-blue;
    color: $darkest-gray;
    font-size: rem(12);
    letter-spacing: rem(0.38);
    line-height: line-height(20, 12);
    text-align: left;
    text-decoration: underline;
    white-space: nowrap;
    &:first-child {
      border-radius: rem(8) 0 0 rem(8);
    }
    &:last-child {
      border-radius: 0 rem(8) rem(8) 0;
    }
  }
  tr {
    position: relative;
    vertical-align: top;
    &:hover,
    &:focus {
      .utility {
        display: flex;
      }
    }
    td {
      @include caption-text;
      @include thin-border;
      background-color: $palest-blue;
      vertical-align: top;
      &::after {
        bottom: 3px;
      }
      &:last-child {
        &::after {
          display: unset;
        }
      }
      &.learner-name {
        min-width: rem(108);
        position: relative;
        a {
          font-size: inherit;
          letter-spacing: normal;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.activity {
        min-width: rem(124);
        .activity-name {
          @include font-weight(semibold);
          line-height: line-height(16, 12);
          margin-bottom: spacing(xxs);
        }
      }
      &.credits {
        min-width: rem(136);
        .credits-total {
          @include font-weight(semibold);
        }
        .has-icon {
          align-items: center;
          display: flex;
          svg {
            font-size: rem(12);
          }
        }
      }
    }
  }
}
.utility {
  align-items: center;
  bottom: rem(10);
  color: $darkest-gray;
  display: flex;
  justify-content: center;
  left: -#{spacing(xs)};
  margin-top: spacing(xs);
  padding: spacing(xxs) spacing(xs);
  z-index: 10;
  @include breakpoint(sm) {
    position: absolute;
  }
  @include breakpoint(lg) {
    background-color: $white;
    border-radius: spacing(xs);
    bottom: unset;
    box-shadow: 1px 3px 8px 2px $boxshadow;
    display: none;
    left: spacing(lg);
  }
  a,
  button {
    padding: 0 spacing(xs);
  }
  > a,
  > button {
    &:first-child {
      color: $accme-blue;
    }
  }
}
.status {
  @include font-weight(semibold);
  align-items: center;
  border-radius: rem(8);
  color: $white;
  display: flex;
  font-size: rem(12);
  justify-content: center;
  line-height: line-height(22, 12);
  padding: 0 spacing(xs) 0 rem(11);
  text-transform: capitalize;
  &:global(.Accepted) {
    background-color: $accme-turquoise;
  }
  &:global(.Not.Processed) {
    background-color: $dark-gray;
  }
  &:global(.Submitted) {
    background-color: $accme-purple;
  }
  &:global(.Rejected) {
    background-color: $accme-red;
    text {
      align-items: center;
      display: flex;
    }
    svg {
      font-size: rem(8);
      height: rem(13);
      margin-left: spacing(xxs);
      width: rem(13);
    }
  }
}
.no-results {
  display: flex;
  justify-content: center;
  margin-bottom: spacing(md);
  width: 100%;
}
