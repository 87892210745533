@import 'globals/scss/theme';

.notification {
  display: flex;
  margin-bottom: 1.75rem;

  .icon {
    color: $accme-red;
    height: 0.75em;
    margin-right: 0.5rem;
    width: 1em;

    &.error {
      color: $accme-red;
    }

    &.info {
      color: $accme-yellow;
    }

    &.success {
      color: $accme-lime-green;
    }
  }

  .description{
    &.error {
      color: $accme-red;
    }

    &.info {
      color: $accme-yellow;
    }
  }

  .actions {
    display: flex;
    padding-top: 0.75rem;

    .action {
      color: $accme-blue;
      font-size: 0.8em;
      text-decoration: underline;

      + .action {
        margin-left: 1rem;
      }
    }
  }
}
