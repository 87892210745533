@import 'src/globals/scss/base';

.tableHeader {
  > tr {
    th {
      color: $accme-olive;
      font-size: rem(12);
      font-weight: 600;
      letter-spacing: 1.5px;
      line-height: 1.67;
      text-transform: uppercase;
    }
  }
}

.table {
  td,
  th {
    border: 0;
    font-size: rem(16);
  }

  td {
    color: $darkest-gray;
    font-family: $font-family-sans-serif-body;
    padding: 0;
    @include breakpoint(sm) {
      padding: initial;
    }
  }
}
