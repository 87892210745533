@import 'src/globals/scss/theme';
@import 'src/globals/scss/base';

.wrapper {
  background: $white;
  max-height: calc(100% - 4rem);
  overflow-y: auto;
  padding: 31px 28px;
  position: relative;
  width: 66vw;

  @include breakpoint(md) {
    width: 50vw;
  }

  .error {
    background: $accme-red;
    margin-bottom: spacing(md);
    padding: rem(10);
    width: 100%;
  }

  .header {
    color: $darkest-gray;
    display: flex;
    justify-content: space-between;
    line-height: 1.5;
    margin-bottom: 1rem;

    h2 {
      font-size: rem(16);
      @include breakpoint(md) {
        font-size: rem(24);
      }
    }

    .close {
      padding: 0;
    }
  }

  .content-wrapper {
    overflow-y: hidden;
    overflow-x: hidden;
  }
}
