@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

:global {
  .faq-content {
    position: relative;

    .close {
      display: none;
    }

    h5 {
      margin: 0 0 spacing(xs);
    }

    ul {
      @include list-unstyled;

      li {
        @include thin-border;
        font-size: rem(14);
        line-height: line-height(22, 14);
        padding: spacing(md) 0;

        * {
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;
        }
      }

      &.links {
        li {
          @include font-weight(semibold);
          font-size: rem(16);
          line-height: 1em;
          padding: spacing(xs) 0;
        }
      }
    }
  }
}

span.bold {
  font-weight: 600;

  + div a {
    color: $accme-dark-blue;
    font-weight: 600;
    text-decoration: underline;
  }
}
