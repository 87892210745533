@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.summary-refiners {
  position: relative;

  @include breakpoint(sm) {
    padding-right: spacing(lg);
  }

  @include breakpoint(lg) {
    padding-left: rem(52);
  }

  .margin-top {
    margin-top: 2.5rem;
  }

  .search-icon {
    background: $accme-lime-green;
    color: $white;

    &:visited,
    &:focus,
    &:focus-visible {
      outline: 2px solid $accme-blue;
    }

    :global(.site-ja) & {
      background: $ja-teal;
    }
  }

  .filters {
    margin-top: spacing(xl);
    &-header {
      @include body-m;
      @include font-weight(semibold);
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: spacing(sm);
    }
    &-selected {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .refiners {
    margin-bottom: spacing(xl);

    .MuiCollapse-entered {
      max-height: rem(500);
      overflow: auto;
    }
  }
  .form-input {
    @include breakpoint(md) {
      width: 90%;
    }
    > div + div {
      margin-top: spacing(sm);
    }
    &-label {
      display: block;
      margin: 0 0 spacing(sm);
    }
    &-checkbox-indent {
      margin-left: spacing(md);
    }
    &-inline {
      align-items: center;
      display: flex;
      > .form-input-radio {
        margin-right: spacing(xxl);
      }
      input {
        margin-right: spacing(sm);
      }
    }
    &--with-delete {
      align-items: center;
      display: flex;
      margin-bottom: spacing(sm);
      label {
        align-items: center;
        display: flex;
        width: 100%;
        @include breakpoint(md) {
          width: calc(100% - #{spacing(xl)});
        }
        > input {
          margin-left: spacing(lg);
          max-width: 25%;
          &:first-child {
            margin-left: 0;
            max-width: unset;
          }
        }
      }
    }
    .error {
      color: $accme-red;
      font-size: rem(12);
      line-height: line-height(20, 12);
      margin: spacing(xxs) rem(12) 0;
    }
  }
  .status {
    @extend %status;
    &--draft {
      background: $accme-red;
    }
    &--active {
      background: $accme-turquoise;
    }
    &--rtc {
      background: $accme-purple;
    }
  }
  &.mobile {
    margin-bottom: spacing(md);
    @include breakpoint-max(lg) {
      display: block;
    }
  }
}
