p.text {
  max-width: 400px;
}

span.bold {
  font-weight: 600;
}

.button-row {
  margin-top: 30px;
}
