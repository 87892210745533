@import 'globals/scss/mixins';

.footer {
  margin-top: 2rem;

  ul {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    li + li {
      margin-top: 1rem;
    }
  }

  .link {
    @include font-weight(bold);
    align-items: center;
    color: $accme-dark-blue;
    display: inline-flex;
    font-size: 0.9rem;

    svg {
      font-size: 1em;
      margin-left: 1rem;
      transform: rotate(180deg);
    }
  }
}
