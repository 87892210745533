@import 'src/globals/scss/base';

.container {
  display: flex;
  flex-direction: column;
  .wrapper {
    display: flex;
    margin: spacing(xs) 0;
    > span {
      align-items: center;
      display: flex;
    }
  }
  .add {
    align-items: center;
    display: flex;
    margin-top: spacing(md);
    button {
      background: $white;
      color: $accme-lime-green;
    }
    span.text {
      margin-left: spacing(sm);
    }
  }
}
