@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.learner-results-card {
  background-color: $pale-blue;
  border-radius: rem(24);
  margin: spacing(sm) 0;
  padding: spacing(md) spacing(md) 0;
  position: relative;
  @include breakpoint(sm) {
    margin-bottom: spacing(md);
    padding-bottom: spacing(md);
    &:hover {
      .activity-info,
      .meta {
        &:last-child {
          margin-bottom: spacing(sm);
        }
      }
      .learner-results-card-utility {
        height: auto;
        transform: scaleY(1);
        .meta {
          margin-bottom: 0;
        }
      }
    }
  }
  &-title {
    @include body-m;
    @include font-weight(semibold);
    color: $accme-dark-blue;
    margin-bottom: spacing(sm);
    @include breakpoint(sm) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: spacing(xs);
    }
  }
  &-cta {
    @include caption-text;
    color: $darkest-gray;
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing(md);
    @include breakpoint(sm) {
      justify-content: flex-end;
      order: 1;
    }
    a.button {
      background-color: $white;
      border: rem(1) solid $darkest-gray;
      border-radius: rem(24);
      margin-left: spacing(md);
      padding: 0 spacing(sm);
      &:hover {
        background-color: $white;
      }
    }
  }
  &-info-container {
    @include caption-text;
    margin: spacing(sm) 0 0;
    @include breakpoint(sm) {
      display: flex;
      justify-content: space-between;
    }
    .info {
      .org-info {
        display: inline-flex;
        margin-bottom: spacing(xxs);
        @include breakpoint(xs) {
          span {
            &:last-child {
              &::before {
                content: '|';
                padding: 0 spacing(xxs);
              }
            }
          }
        }
      }
    }
  }
  .activity-info {
    margin-bottom: spacing(sm);

    .meta-activityFormat {
      font-weight: 600;
    }
  }
  .meta {
    margin-bottom: spacing(sm);
    @include breakpoint(sm) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
    }
    &-type {
      @include font-weight(semibold);
      margin-right: spacing(sm);
    }
    &-id,
    &-date,
    &-other {
      @include breakpoint(sm) {
        &::after {
          content: '|';
          padding: 0 spacing(xxs);
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  .records {
    .record-status {
      span {
        @include font-weight(semibold);
      }
    }
  }
  &-utility {
    @include thin-border-top;
    @include caption-text;
    color: $darkest-gray;
    padding: spacing(sm) 0;
    @include breakpoint(sm) {
      height: 0;
      margin-top: 0;
      padding-bottom: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.3s ease-out;
    }
    .meta {
      margin-bottom: 0;
      @include breakpoint(sm) {
        justify-content: flex-start;
      }
    }
  }
}
