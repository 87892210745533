@import 'src/globals/scss/variables';
@import 'src/globals/scss/mixins';

.board-incomplete {
  color: $accme-red;
}

.board-credit-list {
  list-style-type: none;
  > li::before {
    content: '-';
  }
}

.pharmacy-program {
  @include body-s;
  margin-bottom: spacing(sm);
  word-break: break-word;
  @include breakpoint(sm) {
    @include body-m;
  }
}

.separator {
  border-bottom: 1px solid $darkest-gray;
  transform: scaleY(0.2);
}
