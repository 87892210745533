@import 'globals/scss/mixins';

.section {
  .value {
    @include heading-3;

    + .description {
      @include body-m;
    }
  }

  .description {
    @include body-s;
    margin: 0;
  }
}
