@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.add {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: spacing(md);
  button {
    background: $white;
    color: $accme-lime-green;
    margin-right: spacing(sm);
  }
}
