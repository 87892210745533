@import 'globals/scss/mixins';

.section {
  strong {
    @include heading-3;
  }

  p {
    @include body-m;
    margin: 0;
  }
}

.header {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;

  h2 {
    @include subheading-1;
    @include font-weight(semibold);
    margin: 0;
  }

  > * + * {
    margin-left: 1rem;
  }
}
