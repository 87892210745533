@import 'src/globals/scss/base';
@import 'src/globals/scss/extensions';

.rems-completion {
  :global {
    .form-input-label {
      @include eyebrow;
      color: $accme-olive;
      margin-bottom: 0;
    }
    .form-input {
      margin: 0;
    }
  }
}
