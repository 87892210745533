@import 'src/globals/scss/base';
@import 'src/globals/scss/extensions';

.learner-form-title-container {
  display: block;
}

.caption-text {
  @include caption-text;
  padding: 0;
}

span.bold {
  font-weight: 600;
}

.activity-info {
  display: flex;
  flex-direction: column;
  .activity-info-row {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    @include breakpoint-max(md) {
      flex-direction: column;
    }
    li {
      margin-right: spacing(xs);
    }
  }
}

.aside-controls {
  .recent-learner-btn {
    @extend %status;
    background-color: $accme-peach;
  }
  @include breakpoint-max(md) {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }
}

.recent-learners-rail {
  max-height: 66rem;
  overflow-y: scroll;
  padding-right: spacing(xxl);
}

.recent-learners-header {
  margin-bottom: spacing(lg);
}

.recent-learners {
  list-style: none;
  margin: 0;
  padding: 0;
}

.recent-learner-card {
  display: flex;
  justify-content: space-between;
  max-width: 18rem;
  border-top: 1px solid $medium-gray;
  padding-top: spacing(md);
  padding-bottom: spacing(md);
  &-first {
    border: none;
  }
}

.recent-learner-col {
  display: flex;
  flex-direction: column;
}
