@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.learner-detail-group {
  display: block;
  margin-bottom: spacing(xl);
  &:last-child {
    margin-bottom: 0;
  }
  &-header {
    @include body-m;
    @include font-weight(semibold);
    margin-bottom: spacing(md);
    width: 60%;
    @include breakpoint(sm) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    > div {
      @include font-weight(regular);
    }
  }
  &-content {
    @include list-unstyled;
    li {
      @include thin-border;
      margin-bottom: spacing(xs);
      padding-bottom: spacing(sm);
      @include breakpoint(sm) {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
      &:last-child {
        &::after {
          border-bottom: 0;
          display: unset;
        }
      }
    }
  }
  .meta {
    @include breakpoint(sm) {
      width: 52%;
    }
    > span {
      @include caption-text;
      display: inline-block;
      &::after {
        content: '|';
        padding: 0 spacing(xxs);
      }
      &:first-child {
        width: 100%;
        @include breakpoint(sm) {
          width: auto;
        }
        &::after {
          display: none;
          @include breakpoint(sm) {
            display: inline-block;
          }
        }
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
  .actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    @include breakpoint(sm) {
      width: 48%;
    }
    &-buttons {
      button {
        padding: 0;
        span:first-child {
          padding: 0 rem(5);
        }
      }
    }
    .status {
      @include font-weight(semibold);
      align-items: center;
      border-radius: rem(8);
      color: $white;
      display: flex;
      font-size: rem(12);
      justify-content: center;
      line-height: line-height(22, 12);
      padding: 0 spacing(xs) 0 rem(11);
      text-transform: capitalize;
      &.accepted,
      &.submitted {
        background: $accme-turquoise;
      }
      &.not-processed {
        background: $accme-blue;
      }
      &.rejected {
        background: $accme-red;
        min-width: rem(90);
        text {
          align-items: center;
          display: flex;
        }
        svg {
          font-size: rem(8);
          height: rem(13);
          margin-left: spacing(xxs);
          width: rem(13);
        }
      }
    }
  }
}
