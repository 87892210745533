@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.report {
  @include body-m;
  background-color: $white;
  border-radius: 1.5rem;
  box-shadow: 1px 3px 8px 2px $boxshadow;
  padding: 1.5rem;

  .name {
    @include subheading-1;
    @include font-weight(semibold);
  }

  .footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    text-align: right;

    .cta {
      @include font-weight(bold);
      align-items: center;
      display: inline-flex;

      .backspace,
      .download {
        font-size: 1.25em;
      }

      .backspace {
        margin-left: 0.5em;
        transform: rotate(180deg);
      }

      .download {
        margin-right: 0.3em;
      }
    }
  }
}
