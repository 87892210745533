@import 'globals/scss/functions';
@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.widget {
  background-color: $pale-blue;
  border-radius: rem(24);
  padding: rem(32);

  &.loading {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
