@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.loading-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 1rem;
}

.required {
  color: $accme-red;
}

.section {
  .title {
    color: $darkest-gray;
    font-size: rem(16);
    font-weight: 600;
    margin: 0 0 1rem;
  }

  .select {
    width: 100%;
  }

  .footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding-top: 1.5rem;
  }
}
