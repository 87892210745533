@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.section,
.footer {
  display: grid;
  gap: 1rem;
}

.section {
  width: 100%;

  :global(.form-input) {
    margin: 0;
    width: 100%;
  }

  .footer {
    grid-template-columns: 1fr 1fr;
    padding-top: 1.5rem;
  }
}

.legend {
  @include font-weight(semibold);
}

.required {
  color: $accme-red;
}

.input {
  margin: 0.25rem 0;
  width: 70%;

  &.full {
    width: 100%;
  }
}
