@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

:global {
  .toast-content.static-toast-content {
    margin: auto;
    cursor: pointer;
    &.toast-content-disabled {
      cursor: default;
      color: $lighter-gray;
      svg.icon {
        background: $lighter-gray;
        fill: $dark-gray;
      }
    }
  }
  .restore {
    background: $white;
    margin-right: rem(10);
    svg {
      fill: $accme-lime-green;
    }
  }
}
