@import 'globals/scss/functions';
@import 'globals/scss/mixins';
@import 'globals/scss/theme';

@mixin container-padding {
  padding-left: 1.75rem;
  padding-right: 1.75rem;

  @include breakpoint(sm) {
    padding-left: 4.75rem;
    padding-right: 4.75rem;
  }
}

.loader {
  align-items: center;
  background-color: $palest-blue;
  display: flex;
  height: calc(100vh - 200px);
  justify-content: center;
  width: 100%;
}

.container {
  @include body-m;
  background-color: $palest-blue;
  color: $darkest-gray;
  width: 100%;
}

.banners,
.header,
.content,
.footer {
  @include container-padding;
}

.header {
  display: grid;
  font-size: 0.9rem;
  gap: rem(24);
  overflow-x: hidden;
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;

  @include breakpoint(sm) {
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
  }

  a {
    color: $accme-dark-blue;
  }

  .greeting {
    order: 2;

    @include breakpoint(sm) {
      grid-column: span 2;
      order: 1;
    }
  }

  .notifications {
    order: 1;

    @include breakpoint(sm) {
      order: 2;
    }
  }
}

.content {
  display: grid;
  gap: rem(24);

  @include breakpoint(sm) {
    grid-template-columns: repeat(3, 1fr);
    // if there are only two blocks, only show two columns
    &[data-block-count='2'] {
      grid-template-columns: repeat(2, 1fr);
    }
    grid-template-rows: repeat(2, min-content);

    .block {
      min-width: 0;

      &.expand,
      &:first-child {
        grid-row: span 2;
      }
    }

    .message {
      grid-column: span 2;
    }
  }
}

.footer {
  font-size: 0.8rem;
  padding-bottom: 4.75rem;
  padding-top: 4.75rem;
}
