@import 'globals/scss/mixins';
@import 'globals/scss/functions';
@import 'globals/scss/theme';

.detail-group {
  background-color: $pale-blue;
  border-radius: rem(24);
  margin: 0 0 spacing(md);
  padding: spacing(lg) spacing(sm) spacing(xl);
  position: relative;
  @include breakpoint(sm) {
    padding: spacing(lg) spacing(lg) spacing(xl) rem(36);
  }
  @include breakpoint(md) {
    padding: spacing(lg) rem(44) spacing(xl) rem(44);
  }
  @include breakpoint(lg) {
    padding: spacing(lg) spacing(xxl) spacing(xl) rem(44);
  }
  &-rems {
    margin: 0 0 spacing(lg);
  }
  &-title {
    padding-top: 0;
  }
  &-label {
    margin: 0 0 spacing(sm);
    + .card {
      margin-top: spacing(lg);
    }
  }
  .detail-content {
    color: $darkest-gray;
    &-label {
      @include caption-text;
      margin-bottom: spacing(xxs);
      @include breakpoint(sm) {
        margin-bottom: 0;
      }
    }
  }
}

.radioGroup {
  margin-top: spacing(sm);
}

.required {
  color: $accme-red;
}

.caption {
  font-size: 0.75rem;
}

p {
  white-space: pre-wrap;
}