@import 'src/globals/scss/functions';
@import 'src/globals/scss/variables';

.form {
  .form-content-wrapper {
    height: 200px;
    overflow-y: auto;
  }
}

.loading-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.learners-form-wrapper {
  display: flex;
  flex-direction: column;
}

.title {
  color: $darkest-gray;
  font-size: rem(16);
  font-weight: 600;
  margin: 0;
}

p.description {
  margin: spacing(xs) 0;
}

.sub-heading {
  font-size: rem(14);
  line-height: 1.57;
  margin-top: spacing(xl);
}

.input {
  align-items: center;
  display: flex;
  > div {
    width: rem(84);
  }
  > label {
    color: $darkest-gray;
    font-weight: 600;
    margin-left: spacing(xs);
    margin-top: spacing(xs);
  }
}

.footer {
  .right {
    display: flex;
    justify-content: flex-end;
    &:first-of-type {
      margin-bottom: rem(24);
      margin-top: rem(24);
    }
    &.pharma {
      margin-top: spacing(sm);
    }
  }
}
