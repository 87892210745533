@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

:global {
  header {
    width: 100%;
    .globalnav {
      @include thin-border;
      align-items: center;
      background-color: $lightest-gray;
      border-color: $medium-gray;
      display: none;
      min-height: rem(91);
      padding: 0 rem(76);
      @include breakpoint(sm) {
        display: flex;
      }
      .logo {
        display: flex;
        justify-content: center;
        margin-right: spacing(lg);
        max-width: rem(229);
        @include breakpoint(lg) {
          margin-right: rem(56);
        }
        img {
          max-width: 100%;
        }
        img.ancc-logo {
          padding: rem(20) rem(50);
          max-height: rem(86);
        }
      }
      nav {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        a {
          @include font-weight(semibold);
          color: $darkest-gray;
          display: flex;
          font-size: rem(16);
          margin-right: spacing(lg);
          position: relative;
          @include breakpoint(md) {
            font-size: rem(18);
            margin-right: rem(56);
          }
          &:last-of-type {
            margin: 0;
          }
        }
        .active {
          &::after {
            background-color: $accme-blue;
            bottom: rem(-32);
            content: '';
            height: rem(3);
            position: absolute;
            width: 100%;
            @include breakpoint(md) {
              bottom: rem(-31);
            }
          }
        }
      }
    }
    .mobile-nav {
      display: block;
      @include breakpoint(sm) {
        display: none;
      }
      .ancc-logo {
        max-height: rem(73);
        max-width: 100%;
        padding: rem(20) rem(30);
      }
    }
    :global(.site-ja) & {
      .usercontrol {
        background-color: $ja-blue;
        button.tertiary {
          color: $ja-blue;
        }
      }
      .globalnav {
        nav {
          a {
            color: $darkest-gray;
          }
          .active {
            &::after {
              background-color: $ja-blue;
            }
          }
        }
      }
      .search-box {
        button {
          background-color: $ja-teal;
        }
      }
      button.btn-search--toggle {
        background-color: $ja-teal;
      }
      .expanded-search-container {
        button.btn-search {
          background-color: $ja-teal;
        }
      }
    }
  }
}
