@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.container {
  background-color: #e8f0f4;
  overflow: hidden;
  padding: 2.5rem 1.75rem 7.5rem 1.5rem;
  position: relative;
  width: 100%;
  @include breakpoint(sm) {
    padding: 2.5rem 4.75rem 7.5rem 4.5rem;
  }

  .admin-title {
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .grouped-link {
    background-color: #dee9ee;
    border-radius: 1.5rem;
    margin: 0 0 1.5rem;
    padding: 2rem 1rem 2.5rem;
    position: relative;
  }

  ul {
    margin-bottom: 2.5rem;
    li {
      list-style: none;
      margin-bottom: 0.5rem;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1em;
      padding: 0.5rem 0;
    }
  }

  a {
    color: $accme-blue;
  }
}
