@import 'src/globals/scss/forms';
@import 'src/globals/scss/theme';

.label {
  color: $dark-gray;
  font-size: rem(12);
  font-weight: 600;
  letter-spacing: rem(1.5);
  line-height: 1.66667;
  margin-bottom: spacing(sm);
  text-transform: uppercase;

  .required {
    color: $accme-red;
    margin-left: 0.3rem;
  }
}

.input {
  @include input-styles('%input-styles', '%input-styles--focus', '%input-styles--error', '%input-styles--disabled');
  width: 100%;

  &.offset {
    margin-top: 25.5px;
  }
  &.start-adornment {
    > div {
      > div {
        color: $medium-gray;
        margin-right: -35px;
        z-index: 1;
        + input {
          padding-left: 40px !important;
        }
      }
    }
  }
}
