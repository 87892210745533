@import 'src/globals/scss/base';

.form-container {
  align-items: center;
  display: flex;
  flex: 1;
  .loading-wrapper {
    height: 100%;
    &:first-child {
      flex: 1;
    }
    section.content {
      align-items: center;
      display: flex;
      height: 100vh;
      justify-content: center;
    }
  }
}
