@import 'src/globals/scss/base';
@import 'src/globals/scss/forms';
@import 'src/globals/scss/extensions';
@import 'src/globals/scss/variables';

.board-subheading {
  @include caption-text;
  margin-top: 0;

  .cert-board-button {
    color: $accme-blue;
    margin-left: spacing(xxs);
    position: initial;
    text-decoration: underline;
  }
}

.checkbox {
  margin-top: 1rem;
}
