@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/variables';
@import 'src/globals/scss/extensions';

:global {
  .history-upload {
    h5 {
      margin-bottom: spacing(sm);
    }
    .uploaded-file-list {
      @include list-unstyled;
    }
  }
  .uploaded-file {
    @include body-s;
    background-color: $pale-blue;
    border-radius: rem(24);
    margin: 0 0 spacing(md);
    padding: spacing(md) rem(22);
    position: relative;
    @include breakpoint(sm) {
      padding: spacing(md) spacing(lg);
    }
    a {
      color: $accme-dark-blue;
    }
    &-name {
      align-items: center;
      display: flex;
      svg {
        color: $medium-gray;
        height: rem(30);
        margin-right: spacing(xxs);
        padding: spacing(xs);
        width: rem(30);
      }
    }
    &-meta,
    &-status {
      margin: spacing(xs) 0 0 spacing(lg);
    }
    &-status {
      @include body-m;
      background-color: $white;
      color: $darkest-gray;
      padding: spacing(xs) rem(30);
      width: 60%;
    }
    .tag {
      @extend %status;
      display: inline-flex;
      margin: 0 spacing(sm) 0 0;
      &--added {
        background-color: $accme-blue;
      }
      &--updated {
        background-color: $accme-olive;
      }
      &--rejected {
        background-color: $accme-red;
      }
      &--deleted {
        background-color: $white;
        color: $darkest-gray;
      }
    }
    &-download {
      display: inline-block;
      margin-top: rem(12);
      button.link {
        font-size: rem(14);
        letter-spacing: rem(0.44);
        line-height: line-height(20, 14);
        text-decoration: none;
        span {
          text-decoration: underline;
        }
      }
      &::after {
        color: $darkest-gray;
        content: '|';
        margin: 0 spacing(xs);
        padding: 0 spacing(xxs);
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
