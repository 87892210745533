@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

:global {
  .showhide {
    list-style: none;
    max-width: 100%;
    padding-left: 0;

    &-item {
      overflow: visible;

      &-heading {
        @include button-unstyled;
        width: 100%;

        &:visited,
        &:focus,
        &:focus-visible {
          outline: 2px solid $accme-blue;
        }
      }

      &-title {
        @include body-m;
        align-items: center;
        display: flex;
        padding: rem(10) 0;

        svg {
          background-color: $white;
          border: 0;
          border-radius: 50%;
          box-shadow: 1px 3px 8px 2px $boxshadow;
          fill: $accme-lime-green;
          margin-right: spacing(xs);
          :global(.site-ja) & {
            fill: $ja-teal;
          }
        }
      }

      &-content {
        display: none;
      }

      &--active {
        .showhide-item-content {
          display: block;
          font-size: rem(14);
          line-height: line-height(22, 14);
          padding: 0;
          @include breakpoint(sm) {
            padding-left: rem(32);
          }
        }
      }
    }
  }
}
