@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.activity {
  &-title {
    color: $accme-blue;
    font-family: inherit;
    font-size: inherit;
    :global(.site-ja) & {
      color: $ja-dark-blue;
    }
  }
  &-info {
    @include caption-text;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: spacing(sm);
    @include breakpoint(sm) {
      margin-top: spacing(xxs);
    }
  }
}
.meta {
  @include breakpoint(sm) {
    display: flex;
    &-internal,
    &-claim-date {
      &::before {
        content: '|';
        padding: 0 spacing(xxs);
      }
    }
    &-boards {
      &::before,
      &::after {
        content: '|';
        padding: 0 spacing(xxs);
      }
    }
  }
  &-activity {
    span:last-child {
      &::before {
        content: '|';
        padding: 0 spacing(xxs);
      }
    }
  }
}
