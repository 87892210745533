@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.container {
  background-color: $pale-blue;
  display: grid;
  gap: 1.5rem;
  grid-auto-rows: min-content;
  padding: 1rem 1.75rem;
  width: 100%;

  @include breakpoint(sm) {
    padding: 2rem 4.75rem;
  }

  .link {
    @include font-weight(bold);
    align-items: center;
    display: inline-flex;

    svg {
      font-size: 1em;
      margin-right: 1em;
    }
  }

  .loading {
    align-items: center;
    display: flex;
    height: 200px;
    justify-content: center;
  }
}

.header {
  align-items: center;
  background-color: $accme-turquoise;
  color: $white;
  display: flex;
  padding: 0.75rem 1.5rem;

  .icon {
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    box-shadow: 1px 3px 8px 2px $boxshadow;
    color: $accme-lime-green;
    display: inline-flex;
    height: 2.5rem;
    justify-content: center;
    margin-right: 1rem;
    width: 2.5rem;
  }

  .title {
    @include subheading-1;
    @include font-weight(semibold);
  }

  .blue & {
    background-color: $accme-blue;
  }

  .purple & {
    background-color: $accme-purple;
  }
}

.downloadList {
  height: 30em;
  overflow: auto;
  scroll-behavior: smooth;

  ul {
    list-style: none;
  }

  .content {
    @include body-m;
    background-color: transparentize($accme-turquoise, 0.75);
    padding: 1.5rem;
    margin-top: 0;

    .flexCenter {
      display: flex;
      justify-content: center;
    }

    li {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      padding-top: .9rem;
      padding-bottom: .7rem;

      .icon {
        margin-left: .5rem;
      }
      
      .fileName {
        margin-left: 1rem;
      }
    }

    li:nth-child(even) {
      background-color: transparentize($accme-turquoise, 0.85);
    }

    li:nth-child(even):hover {
      background-color: transparentize($accme-turquoise, 0.6);
    }

    li:nth-child(odd):hover {
      background-color: transparentize($accme-turquoise, 0.7);
    }

    .report {
      @include body-m;
      background-color: $white;
      border-radius: 1.5rem;
      box-shadow: 1px 3px 8px 2px $boxshadow;
      padding: 1.5rem;

      .subtitle {
        @include subheading-1;
        @include font-weight(semibold);
      }
    }

    .blue & {
      background-color: transparentize($accme-blue, 0.75);
    }

    .purple & {
      background-color: transparentize($accme-purple, 0.75);
    }
  }
}

.report {
  .name {
    @include subheading-1;
    @include font-weight(semibold);
    padding-bottom: 16px;
  }

  .footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    text-align: right;

    .cta {
      @include font-weight(bold);
      align-items: center;
      display: inline-flex;

      .backspace,
      .download {
        font-size: 1.25em;
      }

      .backspace {
        margin-left: 0.5em;
      }

      .download {
        margin-right: 0.3em;
      }
    }
  }
}
