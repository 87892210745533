@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

:global {
  .modal-body {
    .activity-data {
      @include body-m;
      color: $darkest-gray;
      .title {
        @include font-weight(semibold);
        margin: rem(6) 0 spacing(xxs);
      }
      .meta {
        margin-bottom: spacing(xl);
      }
      .confirmation {
        @include font-weight(semibold);
      }
    }
  }
  .modal-button-row {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-top: spacing(sm);
    width: 100%;

    @include breakpoint(sm) {
      flex-direction: row-reverse;
    }

    > button {
      &:nth-of-type(2) {
        margin-bottom: spacing(sm);
      }
    }
    .button-split,
    .cta-container {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
    }
    .cta-container {
      margin-left: auto;
    }
  }
}
