@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

:global {
  .activity-detail-actions {
    .close {
      display: none;
    }
    .admin-popup-buttons {
      padding-top: 0.5rem;
      > div {
        padding-top: 0.5rem;
      }
    }
    ul {
      @include list-unstyled;
      li {
        a,
        button {
          @include body-m;
          color: $darkest-gray;
          margin-bottom: spacing(md);
          padding: 0;
          svg {
            margin-right: spacing(xs);
            padding: spacing(xxs);
          }
          &:disabled {
            color: #667;
            cursor: unset;
            svg {
              color: inherit;
            }
          }
        }

        &:last-child:not(.restore-deleted) {
          @include thin-border-top;
          padding-top: spacing(md);
          button {
            color: $accme-red;
            text-decoration: none;
            &:disabled {
              color: #667;
              cursor: unset;
            }
          }
        }
      }
    }
  }
}
