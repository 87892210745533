@import 'src/globals/scss/forms';

.title {
  color: $accme-olive;
  font-size: rem(14);
  font-weight: 700;
  letter-spacing: rem(2.92);
  line-height: 1.57143;
  margin-bottom: spacing(xxs);
  text-transform: uppercase;
}

.subtitle {
  font-size: rem(24);
  line-height: 1.5;
  margin-bottom: spacing(md);
}
