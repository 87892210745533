@import 'src/globals/scss/variables';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.multi-input-text {
  display: flex;
  flex-direction: row;
  > input {
    max-width: 70%;
  }
}

.repeater-option {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  @include breakpoint(sm) {
    flex-direction: row;
  }
}

.inline-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  width: 100%;
  @include breakpoint(sm) {
    width: 65%;
    max-width: rem(320);
  }
  > .repeater-input {
    display: block;
    margin-top: 0;
    margin-right: 1rem;
    min-width: rem(200);
    &-with-delete {
      display: flex;
      flex-direction: row;
      margin-top: 0;
    }
  }
  .form-input-label + .repeater-input {
    margin-top: 0;
  }
  + .inline-input {
    max-width: rem(160);
    @include breakpoint(sm) {
      width: 35%;
      margin-left: spacing(md);
    }
  }
}
