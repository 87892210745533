@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

:global {
  button.toolTipIcon {
    @include button-unstyled;
    align-items: center;
    background: $white;
    border-radius: 50%;
    box-shadow: 1px 3px 8px 2px $boxshadow;
    color: $accme-peach;
    display: inline-flex;
    height: rem(15);
    justify-content: center;
    width: rem(15);
    :global(.site-ja) & {
      color: $ja-orange;
    }
    svg {
      font-size: rem(19);
    }
  }
}
