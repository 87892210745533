@import 'src/globals/scss/mixins';
@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';

.indent {
  margin-left: rem(40);
}

.labelled-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  &--label {
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.625;
    border-radius: 50%;
    color: $darkest-gray;
    flex-basis: 100%;
    margin-left: spacing(md);
    margin-top: spacing(lg);
  }
}
