@import 'globals/scss/functions';
@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.notifications {
  @include font-weight(semibold);
  background-color: $accme-peach;
  border: 0;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  padding: 0.5rem 4.75rem 0.5rem 0.75rem;
  text-align: left;
  width: 100%;

  @include breakpoint(sm) {
    width: calc(100% + 4.75rem);
  }

  svg {
    background: $white;
    border: 0;
    border-radius: 50%;
    box-shadow: 3px 3px 8px 2px $boxshadow;
    fill: $accme-lime-green;
    height: 1.25rem;
    margin-right: spacing(xs);
    width: 1.25rem;
  }
}
