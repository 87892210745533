@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.drawer {
  width: 100vw;

  @include breakpoint(sm) {
    width: 40vw;
  }

  .content {
    padding: 2rem;

    @include breakpoint(sm) {
      padding: 2rem 30% 2rem 2rem;
    }

    strong {
      @include font-weight(semibold);
    }
  }

  .header {
    display: flex;
    justify-content: flex-end;
  }

  .title {
    @include subheading-1;
    @include font-weight(semibold);
    color: $darkest-gray;
    margin-bottom: 1rem;
  }

  .list {
    color: $dark-gray;
    font-size: 0.9rem;
    line-height: 1.25em;
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
