@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

:global {
  .subnav {
    align-items: center;
    background-color: $white;
    box-shadow: rem(2) 0 rem(4) rem(2) $boxshadow;
    display: none;
    justify-content: center;
    @include breakpoint(sm) {
      display: flex;
      justify-content: center;
      padding: spacing(md) spacing(super);
    }
    @include breakpoint(md) {
      justify-content: initial;
      padding: spacing(md) spacing(super) spacing(md) rem(230);
    }
    nav {
      display: flex;
      a {
        align-items: center;
        color: $darkest-gray;
        display: flex;
        font-size: rem(14);
        line-height: line-height(23, 14);
        margin-right: rem(36);
        @include breakpoint(md) {
          font-size: rem(16);
          line-height: line-height(23, 16);
        }
        :global(.site-ja) & {
          color: $darkest-gray;
        }
        svg {
          height: rem(27);
          padding: rem(6);
          width: rem(27);
          @include breakpoint(md) {
            height: rem(30);
            width: rem(30);
          }
        }
        span {
          white-space: nowrap;
        }
        &.active {
          color: $accme-blue;
          :global(.site-ja) & {
            color: $accme-turquoise;
          }
        }
      }
    }
  }
}
