@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';

.form-title-container {
  display: block;
}

// The "recurring" activity field should be hidden on the Edit Activity form as it is not saved on the back-end.
// (It is only used on the Add Activity Form to facilitate adding multiple instances of Start and End dates)
.edit-activity-container [data-sub-group-id='recurring'] {
  display: none;
}

.button-row {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: spacing(md);
  margin-top: spacing(lg);
  width: 100%;

  @include breakpoint(sm) {
    flex-direction: row;
  }

  > button {
    &:last-of-type {
      margin-bottom: spacing(md);

      @include breakpoint(sm) {
        margin-bottom: 0;
      }
    }
  }
}

.checkbox {
  margin-top: 1rem;
}

