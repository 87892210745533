@import 'functions';
@import 'mixins';
@import 'variables';

.main_layout {
  display: flex;
  min-height: calc(100vh - #{rem(261)}); //makes it so container fills the page
  position: relative;
  @include breakpoint(sm) {
    min-height: calc(100vh - #{rem(281)});
  }
  .site-ja & {
    min-height: calc(100vh - #{rem(464)}); //accounts for JA footer logos
    @include breakpoint(sm) {
      min-height: calc(100vh - #{rem(402)});
    }
  }
  .form-container,
  .summary-container {
    background-color: $palest-blue;
    overflow: hidden;
    padding: spacing(md) spacing(sm) spacing(xl);
    position: relative;
    width: 100%;
    @include breakpoint(sm) {
      padding: spacing(xl) rem(28) spacing(xl);
    }
    @include breakpoint(md) {
      padding: spacing(xl) spacing(super) spacing(xl) rem(52);
    }
    @include breakpoint(lg) {
      padding: spacing(xl) spacing(super) rem(120) rem(72);
    }
    .page-navigation {
      margin-bottom: spacing(md);
      @include breakpoint(sm) {
        margin-bottom: spacing(xl);
      }
    }
    .btn-actions,
    .btn-addfilter {
      display: none;
      @include breakpoint-max(lg) {
        display: flex;
      }
    }
  }
  .form-container {
    .button-row {
      @include breakpoint(sm) {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
      > button {
        margin-bottom: spacing(sm);
        @include breakpoint(sm) {
          margin-bottom: 0;
        }
      }
      .button-split,
      .cta-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        @include breakpoint(sm) {
          align-items: flex-end;
        }
      }
      .cta-container {
        margin-left: auto;
      }
    }
    .btn-faq {
      display: none;
      @include breakpoint-max(lg) {
        display: block;
        margin-left: spacing(sm);
      }
    }
  }
  .rail-container {
    background: $lightest-gray;
    color: $darkest-gray;
    position: relative;
    @include breakpoint-max(lg) {
      max-width: 0;
      min-height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.5s;
      width: 0;
      z-index: 999;
      .faq-content,
      .summary-refiners,
      .activity-detail-actions {
        display: none;
        .close {
          display: none;
        }
      }
      &.open {
        box-shadow: 1px 3px 8px 2px $boxshadow;
        padding: spacing(sm);
        .faq-content,
        .summary-refiners,
        .activity-detail-actions,
        .credit-details {
          display: block;
          .close {
            display: block;
            margin: 0 0 spacing(sm) auto;
          }
        }
      }
      &--75 {
        &.open {
          max-width: 75%;
          width: 75%;
        }
      }
      &--full {
        &.open {
          max-width: 100%;
          width: 100%;
        }
      }
    }
    @include breakpoint(lg) {
      max-width: 28%;
      min-width: 28%;
      padding: spacing(xl) spacing(md) rem(120);
    }
  }
}
