@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

.credit-details {
  display: none;
  @include breakpoint(sm) {
    display: block;
  }
  ul {
    @include list-unstyled;
    li {
      @include thin-border;
      margin-bottom: spacing(md);
      padding-bottom: spacing(md);
      h5 {
        margin-bottom: spacing(sm);
        max-width: rem(196);
        + div {
          @include body-m;
          @include font-weight(semibold);
          margin-bottom: spacing(xs);
        }
      }
      div {
        @include body-s;
        margin-bottom: spacing(xxs);
      }
      &:last-child {
        margin-bottom: spacing(sm);
        &::after {
          display: unset;
        }
      }
    }
  }
  a {
    @include body-s;
    align-items: center;
    color: $darkest-gray;
    display: flex;
    svg {
      height: rem(30);
      padding: spacing(xs);
      width: rem(30);
    }
  }
  :global(.open) & {
    display: block;
  }
  :global(.close) {
    display: none;
    @include breakpoint-max(lg) {
      display: block;
      margin: 0 0 spacing(sm) auto;
    }
  }
}
