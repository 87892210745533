@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

:global {
  .summary-results-card {
    background-color: $pale-blue;
    border-radius: rem(24);
    margin: spacing(sm) 0;
    padding: spacing(md) spacing(md) 0;
    position: relative;
    @include breakpoint(sm) {
      margin-bottom: spacing(md);
      &:hover {
        padding-bottom: spacing(md);
        .meta {
          &:last-child {
            margin-bottom: spacing(md);
          }
        }
        .summary-results-card-utility {
          transform: scaleY(1);
          .btn {
            margin: spacing(sm) spacing(md) 0;
          }
        }
      }
    }

    a {
      color: $accme-dark-blue;
    }

    &-status {
      display: flex;
      justify-content: flex-end;
      margin-bottom: spacing(md);
      @include breakpoint(sm) {
        margin-bottom: 0;
        order: 2;
        width: 25%;
      }
      .status {
        @extend %status;
        &--draft {
          background: $accme-red;
        }
        &--active {
          background: $accme-turquoise;
        }
        &--ready-to-close {
          background: $accme-purple;
        }
        &--deleted {
          background: $white;
          .label-text {
            color: $darkest-gray;
          }
        }
      }
    }
    &-title {
      @include input-styles('%input-styles', '%input-styles--focus', '%input-styles--error', '%input-styles--disabled');
      @include breakpoint(sm) {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }
    &--checkbox {
      @extend %checkbox-styles;
      margin-bottom: spacing(xxs);
      .label-text {
        @include body-m;
        @include font-weight(semibold);
        color: $accme-dark-blue;
        :global(.site-ja) & {
          color: $ja-dark-blue;
        }
      }
    }
    .meta {
      @include body-s;
      color: $darkest-gray;
      display: flex;
      flex-flow: column nowrap;
      margin: 0 0 spacing(md) spacing(lg);
      @include breakpoint(sm) {
        flex-direction: row;
        margin-bottom: spacing(sm);
        &--pipe-separated {
          > * {
            &::after {
              content: '|';
              margin: 0 spacing(sm);
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
        transition: margin 0.3s ease-out;
      }
      &--pipe-separated {
        > * {
          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
      &--caption-text {
        @include caption-text;
        justify-content: space-between;
      }
      &-date {
        @include font-weight(semibold);
      }
    }
    &-cta,
    &-utility {
      @include caption-text;
      align-items: center;
      color: $darkest-gray;
      display: flex;

      a {
        color: $darkest-gray;
      }

      .Mui-disabled {
        color: darken($medium-gray, 18%);
      }
    }
    &-cta {
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: spacing(md);
      @include breakpoint(sm) {
        margin-top: 0;
      }
    }
    &-utility {
      @include thin-border-top;
      justify-content: center;
      margin-top: spacing(sm);
      @include breakpoint(sm) {
        margin-top: 0;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 0.3s ease-out;
      }
      .btn {
        margin: spacing(sm) spacing(md);
        @include breakpoint(sm) {
          margin: 0 spacing(md) 0;
          transition: margin 0.3s ease-out;
        }
      }
    }
  }
}
