@import 'globals/scss/mixins';

.banner {
  @include font-weight(semibold);
  align-items: center;
  display: flex;
  justify-content: space-between;

  a,
  a:hover {
    color: inherit;
    font-size: 0.8em;
    margin-left: 1rem;
    text-decoration: underline;
  }

  button {
    color: inherit;
    margin-left: 2rem;
  }
}
