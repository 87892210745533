@import 'src/globals/scss/theme';
.flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  button {
    height: 35px;
    min-width: initial;
  }
  > div {
    // Target the input
    > div {
      height: 35px;
      margin-left: 10px;
    }
  }
  > svg {
    color: $accme-lime-green;
    margin-right: 30px;
  }
}

.path {
  align-items: center;
  display: flex;
  margin-top: 30px;
  text-transform: capitalize;

  > div {
    display: flex;
    justify-content: space-between;

    &.filler {
      display: flex;
      flex-grow: 1;
    }

    > svg {
      color: $accme-lime-green;
      font-size: 1.5rem;
      margin: 0 15px;
    }

    > button {
      background: $accme-red;
      border: 1px solid $accme-red;
      color: $white;
      justify-self: flex-end;

      &:hover {
        color: $accme-red;
      }
    }
  }
}

.soft-validation {
  background: $white;
  box-shadow: 5px 5px 10px $boxshadow-alt;
  margin: rem(20) rem(5);
  padding: 10px;
  > p {
    color: $accme-dark-blue;
    font-style: italic;
    margin: 0;
    > svg {
      font-size: inherit;
      margin-bottom: -2px;
    }
  }
}

.max-width {
  width: 90%;
}
