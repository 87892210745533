@mixin progress-container {
  .progress-bar,
  .progress-bar-fill {
    background-color: $lighter-gray;
    height: 3px;
    margin: rem(15) 0 rem(30);
    width: 100%;
  }
  .progress-bar-fill {
    background-color: $accme-turquoise;
  }
  .progress-label {
    @include body-m;
    color: $darkest-gray;
    .percent {
      @include font-weight(semibold);
    }
  }
}
