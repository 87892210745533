@import '../variables';

@function rem($px) {
  $size: strip-units($px);
  $base-rem: strip-units($base-font-size);
  @return #{$size / $base-rem}rem;
}

@function em($px) {
  $size: strip-units($px);
  $base-rem: strip-units($base-font-size);
  @return #{$size / $base-rem}em;
}

@function line-height($value, $font-size: $base-font-size) {
  $font-value: strip-units($font-size);
  @return #{$value / $font-value};
}

@function strip-units($value) {
  @if type-of($value) == 'number' and not unitless($value) {
    @return $value / ($value * 0 + 1);
  }

  @return $value;
}
