@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

:global {
  .file-uploader {
    @include font-size(16);
    margin-top: spacing(md);
    p {
      margin: 0;
    }
    .error {
      @extend %alert;
      margin-top: rem(20);
    }
    .file-uploader-file {
      @include body-s;
      align-items: center;
      color: $darkest-gray;
      display: flex;
      margin-bottom: rem(10);
      .filename {
        margin-left: rem(10);
      }
      button.clear {
        background: $white;
        color: $accme-lime-green;
        margin-left: rem(10);
        span.text {
          margin-left: spacing(sm);
        }
      }
    }

    .file-uploader-input {
      align-items: center;
      border: 2px dashed $medium-gray;
      border-radius: rem(2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: rem(160);

      // target the clickable dropzone element and make it full width/height.
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: rem(160);
        padding: rem(10);
        width: 100%;

        &:visited,
        &:focus,
        &:focus-visible {
          outline: 2px solid $accme-blue;
        }
      }
    }

    .file-uploader-instructions {
      @include font-weight(semibold);
      text-align: center;
    }

    .file-uploader-note {
      @include font-size(12);
      margin-top: rem(6);
      text-align: center;
    }

    .progress-container {
      background-color: $white;
      min-height: rem(160);
      padding: rem(30);

      .file-uploader-note {
        @include caption-text;
        color: $darkest-gray;
        text-align: start;
      }

      @include progress-container;
    }
  }
  .link-like {
    color: $accme-dark-blue;
    text-decoration: underline;
    &:hover {
      color: $accme-blue;
      cursor: pointer;
    }
    :global(.site-ja) & {
      color: $ja-dark-blue;
      &:hover {
        color: $accme-turquoise;
      }
    }
  }
}
