@import 'globals/scss/mixins';
@import 'globals/scss/theme';

.widget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sections {
  @include body-m;

  > * + * {
    margin-top: 2rem;
  }

  .description {
    @include body-s;
  }
}
