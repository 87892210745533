@import 'src/globals/scss/base';
@import 'src/globals/scss/extensions';

.form-grid {
  padding-top: spacing(sm);
  .input > div > h5 {
    @include eyebrow;
    color: $accme-olive;
    margin-bottom: 0;
  }
  .input > div > h5 + div {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.pill-box {
  margin-bottom: spacing(sm);
}

.match-pill {
  @include caption-text;
  border-radius: 8px;
  color: $white;
  font-weight: 600;
  margin: 24px 0 23px 3px;
  padding: 3px 14px;
  &--fail {
    background-color: $accme-red;
  }
  &--success {
    background-color: $accme-turquoise;
  }
}

.add {
  align-items: center;
  display: flex;
  margin-top: spacing(md);
  button {
    background: $white;
    color: $accme-lime-green;
  }
  span.text {
    margin-left: spacing(sm);
  }
  .disabled {
    color: $medium-gray;
  }
}

.input-wrapper {
  &--small {
    min-width: 10rem;
    width: 30%;
  }
}

.button-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: spacing(md);
  margin-top: spacing(lg);
  width: 100%;
  @include breakpoint-max(md) {
    flex-direction: column-reverse;
    > button {
      margin-bottom: spacing(sm);
      width: 100%;
    }
  }
}

.certifying-board-group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  > div {
    width: 100%;
  }
}

.error-text {
  color: $accme-red;
}
