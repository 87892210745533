@import 'globals/scss/mixins';

.greeting {
  .title {
    @include heading-4;
  }

  .info {
    margin: 0.3em 0;
  }

  .navigation {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  .providerUL {  
      li { 
        margin-left: 20px;
        list-style: initial; 
        display: list-item;
      }
  }
}
