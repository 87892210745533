@import 'src/globals/scss/variables';
@import 'src/globals/scss/functions';
@import 'src/globals/scss/mixins';
@import 'src/globals/scss/extensions';

:global {
  .MuiSnackbar {
    &-anchorOrigin {
      // Specificity + !importants matter here.
      &TopCenter {
        left: 0 !important;
        top: 0 !important;
        @include breakpoint(sm) {
          transform: translateX(0%) !important;
        }
      }
      &BottomCenter {
        bottom: 0 !important;
        left: 0 !important;
        @include breakpoint(sm) {
          transform: translateX(0%) !important;
        }
      }
    }
  }
  .toast-content {
    @include body-m;
    @include font-weight(semibold);
    align-items: center;
    display: flex;
    padding: rem(2) rem(28);
    width: 100%;
    &-centered {
      justify-content: center;
    }
    svg.icon {
      background: $white;
      border: 0;
      border-radius: 50%;
      box-shadow: 1px 3px 8px 2px $boxshadow;
      fill: $accme-lime-green;
      height: rem(40);
      margin-right: spacing(sm);
      padding: spacing(xs);
      width: rem(40);
      :global(.site-ja) & {
        fill: $ja-teal;
      }
    }
    .close {
      align-items: center;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      margin-left: auto;
      padding: spacing(xxs);
      svg {
        fill: $white;
        font-size: rem(20);
      }
      &:hover {
        background: rgba($color: #fff, $alpha: 0.2);
      }
    }
    > button {
      color: inherit;
      font-family: $font-family-sans-serif-body;
      font-size: rem(16);
      font-weight: 600;
      text-decoration: underline;
      &:hover {
        background: none;
        color: inherit;
      }
    }
    > a {
      &,
      &:hover,
      &:focus,
      &:focus-visible,
      &:visited {
        color: inherit;
        font: inherit;
        text-decoration: underline;
      }
    }
  }
}
